import {createSlice} from "@reduxjs/toolkit";

export const myPlansSlice =createSlice({
        name:'myPlansSlice',
        initialState:{
            value:undefined,
            singleData:undefined,
            freeTrial:undefined
      },
      reducers:{
          get_my_plans:(state,action)=>{
              state.value=action.payload.myPlansData;
            },
            get_my_plans_singleView:(state,action)=>{
              state.singleData=action.payload.singleViewData
            },  
            get_free_trial_data:(state,action)=>{
              state.freeTrial=action.payload.freeTrialData
            }
      } 
    })
    
    export const {get_my_plans,get_my_plans_singleView,get_free_trial_data}=myPlansSlice.actions
    export default myPlansSlice.reducer