import React, { useEffect, useState } from "react";
import TopBar from "../../TopBar";
import "../../../Css/Products/products.css";
import { ProductCard } from "../../Custom Components/ProductCard";
import LogisticsSvg from "../../../Assets/Icons/Logistics.svg";
import HotelSvg from "../../../Assets/Icons/hotel_1_.svg";
import EducationSvg from "../../../Assets/Icons/mortarboard.svg";
import RestaurantSvg from "../../../Assets/Icons/Restuarent.svg";
import TextileSvg from "../../../Assets/Icons/sewing-machine_2_.svg"
import AviationSvg from "../../../Assets/Icons/delivery_1_.svg"
import hospitalIcon from "../../../Assets/Icons/hospital_1_.svg";
import { Footer } from "../../Footer";
// import { productDetailsData} from "../../JS/productDetails";
import { ProductDetailsCard } from "../../Custom Components/ProductDetailsCard";
import { geAllPackageForCustomerWebsite, packageAPICall } from "../../../API/Package/packageAPI";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import jwtDecode from "jwt-decode";
import { cartAPICall } from "../../../API/Cart/cartAPI";
import superMarket from "../../../Assets/Icons/superMarket.svg";
import { warningAlertMessage } from "../../JS/alertFunction";
import { getLocation } from "../../../API/Login/loginUserAPI";
import man from "../../../Assets/Images/New/Nastroyka.png"
import axios from "axios";
import { useTranslation } from 'react-i18next';
import countryList from "../../../Assets/JSON/countryListWithCode.json"
import { get_my_plans_singleView } from "../../../Redux/Profile/myPlansSlice";
import store from "../../../Redux/store";
export const Products = (props) => {
  const { t } = useTranslation();
  const {setIsRendered,isRendered}=props
  let navigate = useNavigate();
  // get package details
  const productDeetailsData=useSelector((state)=>state.packageList.value)
  const token=localStorage.getItem('userToken')
  const cartData=useSelector((state)=>state.packageList.cartValue)
  const packageForCustomer=useSelector((state)=>state.packageList.packageCustomer)

  const [productDetailsOpen,setProductDetailsOpen]=useState(false)
  const [singleProductDetails,setSingleProductDetails]=useState([])
  const [isModify,setIsModify]=useState(false)
  const currentCounry=useSelector((state)=>state.locationList.value)
  const country=localStorage.getItem("country")
  // const productDeetailsData=productDetailsData
  
  const clickViewProducts=(id)=>()=>{
    setProductDetailsOpen(!productDetailsOpen)
    let filterArray=productDeetailsData.filter((obj)=>
        obj?._id===id
    )
    filterArray.length!==0?setSingleProductDetails(filterArray[0]):setSingleProductDetails([])
  }


  useEffect(()=>{
    const countryExists = countryList.filter(item => item.code === country);
    if(currentCounry!==undefined){
       packageAPICall()
       geAllPackageForCustomerWebsite({country:countryExists[0]?.name})
       
    }
  },[currentCounry,country])

  useEffect(()=>{
    localStorage.removeItem("singleViewId")
    store.dispatch(get_my_plans_singleView({singleViewData:undefined}))
  },[])

 

  const [filteredData,setFilteredData]=useState([])

  useEffect(()=>{
    if(packageForCustomer?.data !== undefined){
      const dataCopy = JSON.parse(JSON.stringify(packageForCustomer.data));
      const sortedArray = dataCopy.sort((a, b) => b.released - a.released);
      setFilteredData(sortedArray)
    }
    
  },[packageForCustomer])
 
 
  const updateListner=()=>{
    setIsModify(!isModify)
    setIsRendered(!isRendered)
   }




// const initialArray=[]
// const addToCartBtnFn=(id)=>()=>{
 
//   const isTokenExists = localStorage.getItem('userToken') !== null;
//   const existingArray = JSON.parse(localStorage.getItem('myArray')) || [];

//   if(isTokenExists){
//     // Add the new item to the existing array
//   if(existingArray?.some(obj=>obj===id)){
//     window.alert("aleady exist.Please Go to Cart")
//   }
//   else{
//     existingArray.push(id);
//   }
//   // Store the modified array back in localStorage
//   localStorage.setItem('myArray', JSON.stringify(existingArray));
//   }
//   else{
//     console.log('not exist');
//     navigate("/signIn");
//   }
  
// }

// my plans navigation function
const myPlansNavigationFn=()=>{
  navigate("/profileinfomyplans");
}
const cartNavigationFn=()=>{
  navigate("/profileinfomycart");
}

const addToCartBtnFn=(id,r)=>()=>{
  const isTokenExists = localStorage.getItem('userToken') !== null;
  const token=localStorage.getItem('userToken')
  const booyToApi={
     cusId:token !==null &&jwtDecode(token)?._id,
    //  cart:productDeetailsData?.filter((obj)=>obj?._id===id)
    cart:[{id:id}]
  }
  if(isTokenExists){
    if(cartData?.cart?.some(item => item._id === id)){
      // if(window.confirm("Alredy exist.Please go to Cart")===true){
      //   navigate("/profileinfomycart");
      //  }
      warningAlertMessage("Item Already Exist in the Cart!","Do You Want to Proceed to Cart?",cartNavigationFn,"cart")
  }
  else if(r?.isPurchase===true){
    // alert("already purchased")
    warningAlertMessage("Item Already Purchased!","Go To My Plans...",myPlansNavigationFn,"plan")
  }
  else{
    cartAPICall(booyToApi,updateListner)
  }
  }
  else{
    navigate("/signIn");
  }
}

const purchseBtnClick=(r)=>()=>{
  let data = {
    packageName:r?.packageName,
    durationType:r?.durationType
  }
  localStorage.setItem("data",JSON.stringify(data))
  navigate("/productreview");
}






  return (
    <>
      {/* <TopBar /> */}
      <div className="products-main-container">
      <div className="products-content-container">
        <p className="product-heading">{t("erpProducts")}</p>
        {/* <p>1-{state?.countryName}</p> */}
        <div className="products-sub-content-container">
          <p className="product-sub-content">
            {t("erpProductsdes")}
          </p>
        </div>
      </div>
      <div className="products-man-image">
        <img src={man} alt="" />
      </div>
      <div className="products-card-container">
        {
          
          filteredData?.map((r,i)=>(
            <ProductCard
              backgroundColor={r?.hexCode
                // r.packageName==="ERP-Restaurant"?
                // "#e8efff"
                // :r.packageName==="ERP-Textile"?
                // "#fce8f0"
                // :r.packageName==="ERP-Logistics"?
                // "#ff8800"
                // :r.packageName==="ERP-Hotel"?
                // "#e9f8f6"
                // :r.packageName==="ERP-Education"?
                // "#f2f5e8"
                // :r.packageName==="ERP-Aviation"?
                // "#fff9e8"
                // :r.packageName==="ERP-Hospital"?
                // "rgb(247 195 137 / 44%)"
                // :r.packageName==="ERP-SuperMarket"?
                // "#e9f5e8"
                // :r.packageName==="ERP-Autoparts"?
                // "#ecc9ff"
                // :undefined
              }
              headingColor={r?.hexCode
                // r.packageName==="ERP-Restaurant"?
                // "#004CFF"
                // :r.packageName==="ERP-Textile"?
                // "#DB0058"
                // :r.packageName==="ERP-Logistics"?
                // "#ff8800"
                // :r.packageName==="ERP-Hotel"?
                // "#08AF9E"
                // :r.packageName==="ERP-Education"?
                // "#719200"
                // :r.packageName==="ERP-Aviation"?
                // "#FFBB00"
                // :r.packageName==="ERP-Hospital"?
                // "#FF8800"
                // :r.packageName==="ERP-SuperMarket"?
                // "#0F9200"
                // :r.packageName==="ERP-Autoparts"?
                // "rgb(142 20 208)"
                // :undefined
              }
              borderColor={r?.hexCode
                // r.packageName==="ERP-Restaurant"?
                // "#004CFF"
                // :r.packageName==="ERP-Textile"?
                // "#DB0058"
                // :r.packageName==="ERP-Logistics"?
                // "#ff8800"
                // :r.packageName==="ERP-Hotel"?
                // "#08AF9E"
                // :r.packageName==="ERP-Education"?
                // "#719200"
                // :r.packageName==="ERP-Aviation"?
                // "#FFBB00"
                // :r.packageName==="ERP-Hospital"?
                // "#FF8800"
                // :r.packageName==="ERP-SuperMarket"?
                // "#0F9200"
                // :r.packageName==="ERP-Autoparts"?
                // "rgb(142 20 208)"
                // :undefined
              }
              color={r?.hexCode
                // r.packageName==="ERP-Restaurant"?
                // "#004CFF"
                // :r.packageName==="ERP-Textile"?
                // "#DB0058"
                // :r.packageName==="ERP-Logistics"?
                // "#ff8800"
                // :r.packageName==="ERP-Hotel"?
                // "#08AF9E"
                // :r.packageName==="ERP-Education"?
                // "#719200"
                // :r.packageName==="ERP-Aviation"?
                // "#FFBB00"
                // :r.packageName==="ERP-Hospital"?
                // "#FF8800"
                // :r.packageName==="ERP-SuperMarket"?
                // "#0F9200"
                // :r.packageName==="ERP-Autoparts"?
                // "rgb(142 20 208)"
                // :undefined
              }
             
              Url={`data:name;base64,${r?.logo?.data}`
                // r.packageName==="ERP-Restaurant"?
                // RestaurantSvg
                // :r.packageName==="ERP-Textile"?
                // TextileSvg
                // :r.packageName==="ERP-Logistics"?
                // LogisticsSvg
                // :r.packageName==="ERP-Hotel"?
                // HotelSvg
                // :r.packageName==="ERP-Education"?
                // EducationSvg
                // :r.packageName==="ERP-Aviation"?
                // AviationSvg
                // :r.packageName==="ERP-Hospital"?
                // hospitalIcon
                // :r?.packageName==="ERP-SuperMarket"?
                // superMarket
                // :undefined
              }
              buttonLabel="View Plans"
              heading={r.packageName}
              description={r.description}
              clickViewMore={clickViewProducts(r._id)}
              amount={r.sellingPrice}
              duration={r?.durationType}
              isPurchase={r.released}
              data={r}
              id={r?._id}
              // onClick={addToCartBtnFn(r?._id,r)}
              onClick={purchseBtnClick(r)}
              // onMouseUp={saveToLocalStorage}
            />
              ))
            }

            
      </div>
      </div>


      {
        productDetailsOpen?
        <ProductDetailsCard 
         closeClick={clickViewProducts("")}
         data={singleProductDetails}
         
         />:""
      }
      <Footer />
    </>
  );
};
