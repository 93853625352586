import axios from "axios";
import { useSelector } from "react-redux";
import store from "../Redux/store";
import { get_geoLocation_list } from "../Redux/Login/geoLocationSlice";

//  const country=localStorage.getItem("country")
//   const country=store.getState().locationList.value||{country_name:"India"}

// local base url
//    axios.defaults.baseURL= process.env.REACT_APP_LOCAL_BASE_URL
// console.log("urlandpath",country);
//production base url
// if (country?.country_name === "India") {
//     axios.defaults.baseURL = process.env.REACT_APP_PRODUCTION_URL;
//   } else {
//     axios.defaults.baseURL = process.env.REACT_APP_PRODUCTION_URL_UAE;
//   }


// export const changeBaseURL = (newBaseURL) => {
//     // axios.defaults.baseURL = newBaseURL;
//     console.log("newbaseurl",newBaseURL);
//     if (newBaseURL === "India") {
//         axios.defaults.baseURL = process.env.REACT_APP_PRODUCTION_URL;
//       } else {
//         axios.defaults.baseURL = process.env.REACT_APP_PRODUCTION_URL_UAE;
//       }
    
// }


//  axios.defaults.baseURL= process.env.REACT_APP_LOCAL_BASE_URL

       export const changeBaseUrl=(country)=>{
        console.log(country);
        store.dispatch(get_geoLocation_list({geolocationData:country}))
        if (country === "IN") {
            axios.defaults.baseURL = process.env.REACT_APP_PRODUCTION_URL;
          } else {
            axios.defaults.baseURL = process.env.REACT_APP_PRODUCTION_URL_UAE;
          }
        // axios.defaults.baseURL= process.env.REACT_APP_LOCAL_BASE_URL
        
    
}






const TOKEN=localStorage.getItem("userToken")

axios.defaults.headers.common["Authorization"]=`Bearer ${TOKEN}`
export const HEADERS ={
    headers:{
        "Content-Type":"application/json",
    },
};

export const HEADER_QUERY ={
    "Content-Type":"application/json"
};

export const HEADER_FORM_DATA ={
    headers:{
       "Content-Type":"multipart/form-data",
    },
};