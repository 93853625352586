import { Select,MenuItem, Autocomplete, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import '../../../Css/Profile/profileInfo.css'
import { CustomInput } from '../Login/CustomInput'
import { updateUserProfileAPICall } from '../../../API/Login/updateProfileAPI'
import { useSelector } from 'react-redux'
import { getUserInfoAPICall } from '../../../API/Login/Profile/profileInfoAPICall'
import SuccessSnackBar from '../../Custom Components/SuccessSnackBar'
import ErrorSnackBar from '../../Custom Components/ErrorSnackBar'
import Button from '@mui/joy/Button';
import countryList from "../../../Assets/JSON/countryListWithCode.json"
import profileman from "../../../Assets/Images/New/Profile.png"
import demoimage from "../../../Assets/Images/New/Demo 2.png"

export const ProfileInfo = () => {
    
    let navigate = useNavigate();
    let editSkipped=localStorage.getItem("editSkipped")
    let companyCode=localStorage.getItem("companyCode")
    const userInfo=useSelector((state)=>state.profileInfoSlice.value)
    const currentCounry=useSelector((state)=>state.locationList.value)
    
    const clickSkip=()=>{
        localStorage.setItem("editSkipped",true)
        navigate('/')
    }
    const profileInfoFormInitial={
        firstName:"",
        lastName:"",
        org:"",
        businessEmail:"",
        country:"",
        mobile:"",
        website:"",
        companyCode:""
    }
    const [profileInfoForm,setProfileInfoForm]=useState(profileInfoFormInitial)
    const [checkEditBtn,setCheckEditBtn]=useState(profileInfoFormInitial)
    const [selectedCountry,setSelectedCountry]=useState([])
    const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    const [snackMsg, setSnackMsg] = useState("oops!");
    const [isLoading,setIsLoading] = useState(false);

    const closeSuccessSnackbar=()=>{
        setOpenSuccessSnackbar(false)
      }
    const closeErrorSnackbar=()=>{
    setOpenErrorSnackbar(false)
    }


    const getProfileInfoChange=(key)=>(e,newValue)=>{
        const{value}=e.target;
        if(key==="firstName"){
            setProfileInfoForm({...profileInfoForm,firstName:value})
        }
        if(key==="lastName"){
            setProfileInfoForm({...profileInfoForm,lastName:value})
        }
        if(key==="org"){
            setProfileInfoForm({...profileInfoForm,org:value})
        }
        if(key==="businessEmail"){
            setProfileInfoForm({...profileInfoForm,businessEmail:value})
        }
        if(key==="country"){
            setProfileInfoForm({...profileInfoForm,country:newValue?.name})
            setSelectedCountry(newValue)
        }
        if(key==="mobile"){
            setProfileInfoForm({...profileInfoForm,mobile:value})
        }
        if(key==="website"){
            setProfileInfoForm({...profileInfoForm,website:value})
        }
    }

    const bodyToApi={
        firstName: profileInfoForm?.firstName,
        lastName: profileInfoForm?.lastName,
        companyType: profileInfoForm?.org,
        buissinessEmailAddress: profileInfoForm?.businessEmail,
        country: profileInfoForm?.country,
        mobile: profileInfoForm?.mobile,
        website: profileInfoForm?.website
    }

    const clickEditProfile=()=>{
        setIsLoading(true)
        updateUserProfileAPICall(bodyToApi,setOpenSuccessSnackbar,setOpenErrorSnackbar,setSnackMsg,setIsLoading,clickSkip)
    }

    useEffect(()=>{
        if(currentCounry!==undefined){
        getUserInfoAPICall({companyCode:companyCode})
        }
      },[currentCounry])

    useEffect(()=>{
        editSkipped==="true" ? setCheckEditBtn(false):setCheckEditBtn(true)
    },[editSkipped])

    useEffect(()=>{
        if(userInfo!==undefined){
            userInfo.info!==undefined &&
            setProfileInfoForm({...profileInfoForm,firstName:userInfo.info?.firstName,
                                                    lastName:userInfo.info?.lastName,
                                                    org:userInfo.info?.companyType,
                                                    businessEmail:userInfo.info?.buissinessEmailAddress,
                                                    country:userInfo.info?.country,
                                                    mobile:userInfo.info?.mobile,
                                                    website:userInfo.info?.website,
                                                    companyCode:companyCode    
                                                })
            let filteredArray=countryList.filter((obj)=>
                obj?.name===userInfo.info?.country
            )
            setSelectedCountry(filteredArray.length!==0?filteredArray[0]:null)
        }
    },[userInfo])
    
    return(
        <>
        {/* <div className="profile-info-container">
            <div className="profile-info-heading">
            <div className="profile-info-heading-left">
                <h3>{checkEditBtn?"Tell Us About Yourself":"Profile"}</h3>  
            </div>

            {
            checkEditBtn&&
            <div className="profile-info-heading-right">
                <p className="skip-btn" onClick={clickSkip}>Skip</p>  
            </div>
            }
            </div>
            <div className="profile-info-form">
                    <div className={`custom-login-input profile-info-input`}>
                        <p>First Name</p>
                        <input type="text" value={profileInfoForm?.firstName}  onChange={getProfileInfoChange("firstName")} />
                    </div>
                    <div className={`custom-login-input profile-info-input`}>
                        <p>Last name</p>
                        <input type="text" value={profileInfoForm?.lastName}  onChange={getProfileInfoChange("lastName")} />
                    </div>
                    <div className={"custom-login-input profile-info-input"}>
                        <p>Organisation</p>
                        <input type="text" value={profileInfoForm?.org}  onChange={getProfileInfoChange("org")} />
                    </div>
                    <div className={`custom-login-input profile-info-input`}>
                        <p>Business Email Address</p>
                        <input type="text" value={profileInfoForm?.businessEmail}  onChange={getProfileInfoChange("businessEmail")} />
                    </div>
                    <div className={"custom-login-input profile-info-input auto-complete"}>
                    <p>Country</p>
                        <Autocomplete
                          sx={{width:"100%"}}
                          value={selectedCountry}
                          options={countryList}
                          getOptionLabel={(option)=>option?.name}
                          renderInput={(params)=><TextField {...params}/>}
                          onChange={getProfileInfoChange("country")}
                        />
                    
                      
                    </div>
                    <div className={`custom-login-input profile-info-input`}>
                        <p>Phone</p>
                        <input type="text" value={profileInfoForm?.mobile}  onChange={getProfileInfoChange("mobile")} />
                    </div>
                    <div className={`custom-login-input profile-info-input`}>
                        <p>Website name</p>
                        <input type="text" value={profileInfoForm?.website}  onChange={getProfileInfoChange("website")} />
                    </div>
                    {
                    <div className={`custom-login-input profile-info-input`}>
                        <p>Company Code</p>
                        <input type="text" value={profileInfoForm?.companyCode}  disabled />
                    </div>
                    }
                
            </div>
            <div className="profile-info-form-submit">
          
            <Button loading={isLoading} onClick={clickEditProfile} sx={{marginBottom:checkEditBtn?0:"3%"}}>
                {checkEditBtn?"Submit":"Edit Profile"}
            </Button>
            {
            checkEditBtn&&
            <div className="profile-info-footer">
                <p>By Clicking Submit,you accept our <a className="profile-info-footer-link" href="">Subscription Agreement</a>  and <a className="profile-info-footer-link" href="">Privacy policy</a></p>
            </div>
            }
            </div>
        </div> */}
        <div className="profile-info-container">
           <div className="free-demo-man-image" style={{width:"35%",bottom:0,left:1}}>
              <img src={profileman} alt="" style={{width:"100%"}}/>
            </div>
            <div className="free-trial-section" >
                <h1 className="demo-header">Update Your Profile</h1>
                <p className="free-trial-left-para">
                   Keep your contact information up-to-date to ensure you receive the latest news, updates, and offers from EYE-T ERP. Our user-friendly interfaces, powerful features, and dedicated customer support set us apart as a trusted partner in your business success.
                </p>
               
            </div>
            <div className="free-demo-image" style={{display:"flex",justifyContent:"flex-end",right:0,top:"53%"}}>
                <img src={demoimage} alt="" />
            </div>
            <div className="free-trial-blue-ctn" style={{right:"7%"}}>
                    <h1 className="demo-blue-header">Profile</h1>
                      <div style={{display:"flex",flexWrap:"wrap"}}>
                    <div className="free-demo-input custom-login-input auto-complete" style={{width:"75%"}}>
                        <p>First Name</p>
                        <input type="text"
                         onChange={getProfileInfoChange("firstName")}
                        value={profileInfoForm?.firstName}
                        />
                    </div>
                    <div className="free-demo-input">
                    <p>Last name</p>
                        <input type="text" value={profileInfoForm?.lastName}  onChange={getProfileInfoChange("lastName")} />
                    </div>
                    {/* <div className="free-demo-input">
                        <p>Business Name</p>
                        <input type="text"/>
                    </div> */}
                    <div className="free-demo-input">
                    <p>Organisation</p>
                        <input type="text" value={profileInfoForm?.org}  onChange={getProfileInfoChange("org")} />
                    </div>
                    <div className="free-demo-input">
                    <p>Business Email Address</p>
                        <input type="text" value={profileInfoForm?.businessEmail}  onChange={getProfileInfoChange("businessEmail")} />
                    </div>
                
                    <div className="free-demo-input">
                        <p>Country</p>
                    <div className="free-demo-mobile-input auto-complete">
                        <Autocomplete
                          sx={{width:"100%"}}
                          value={selectedCountry}
                          options={countryList}
                          getOptionLabel={(option)=>option?.name}
                          renderInput={(params)=><TextField {...params}/>}
                          onChange={getProfileInfoChange("country")}
                        />
                    </div>
                    </div>
                    <div className="free-demo-input">
                    <p>Phone</p>
                        <input type="text" value={profileInfoForm?.mobile}  onChange={getProfileInfoChange("mobile")} />
                    </div>
                    <div className="free-demo-input">
                    <p>Website name</p>
                        <input type="text" value={profileInfoForm?.website}  onChange={getProfileInfoChange("website")} />
                    </div>
                    <div className="free-demo-input">
                    <p>Phone</p>
                        <input type="text" value={profileInfoForm?.mobile}  onChange={getProfileInfoChange("mobile")} />
                    </div>
                    </div>
                    <button className="btn btn-get-demo" loading={isLoading} onClick={clickEditProfile} sx={{marginBottom:checkEditBtn?0:"3%"}}>
                        {checkEditBtn?"Submit":"Edit Profile"}
                    </button>
            {
            checkEditBtn&&
            <div className="profile-info-footer">
                <p>By Clicking Submit,you accept our <a className="profile-info-footer-link" href="">Subscription Agreement</a>  and <a className="profile-info-footer-link" href="">Privacy policy</a></p>
            </div>
            }
                   
            </div>  
        </div>

        <SuccessSnackBar open={openSuccessSnackbar} handleClose={closeSuccessSnackbar} message={snackMsg}/>
        <ErrorSnackBar open={openErrorSnackbar} handleClose={closeErrorSnackbar} message={snackMsg}/>
        </>
      
    )
}