import React, { useEffect, useState } from 'react'
import "./productReview.css"
import image from "../../../Assets/Icons/New/hvordan-finne-forbrukslan.png"
import { filterPackgeAPICall } from '../../../API/Package/packageAPI'
import { useSelector } from 'react-redux'
import jwtDecode from "jwt-decode";
import { cartAPICall } from '../../../API/Cart/cartAPI'
import { useNavigate } from 'react-router-dom'
import { warningAlertMessage } from '../../JS/alertFunction'
import countryList from "../../../Assets/JSON/countryListWithCode.json"

export const ProductReview = () => {
    const country=localStorage.getItem("country")
    let navigate = useNavigate();
    const filterDataFull=useSelector((state)=>state.packageList.filterPackage)
    const cartData=useSelector((state)=>state.packageList.cartValue)
    const data=JSON.parse(localStorage.getItem("data"))
    const currentCounry=useSelector((state)=>state.locationList.value)
    const [filterData,setFilterData]=useState([])

    console.log(data);

    const updateListner=()=>{
        // setIsModify(!isModify)
        // setIsRendered(!isRendered)
       }
    
    const myPlansNavigationFn=()=>{
        navigate("/profileinfomyplans");
      }
      const cartNavigationFn=()=>{
        navigate("/profileinfomycart");
      }
    const addToCartFunction=(id,isPurchase)=>()=>{
        const isTokenExists = localStorage.getItem('userToken') !== null;
        const token=localStorage.getItem('userToken')
       const booyToApi={
        cusId:token !==null &&jwtDecode(token)?._id,
       //  cart:productDeetailsData?.filter((obj)=>obj?._id===id)
       cart:[{id:id}]
     }
     if(isTokenExists){
        if(cartData?.packages?.some(item => item._id === id)){
          warningAlertMessage("Item Already Exist in the Cart!","Do You Want to Proceed to Cart?",cartNavigationFn,"cart")
      }
      else if(isPurchase===true){
        warningAlertMessage("Item Already Purchased!","Go To My Plans...",myPlansNavigationFn,"plan")
      }
      else{
        cartAPICall(booyToApi,cartNavigationFn,updateListner)
      }
      }
      else{
        navigate("/signIn");
      }
    }

    useEffect(()=>{
      const countryExists = countryList.filter(item => item.code === country);
      if(currentCounry!==undefined){
        filterPackgeAPICall(
          {
            packageName:data?.packageName,
            durationType:data?.durationType,
            country:countryExists[0]?.name
          })
      }
    },[currentCounry,country])

    useEffect(()=>{
      const countryExists = countryList.filter(item => item.code === country);
      let filteredDataa=filterDataFull?.filter((r,i)=>{
        return r?.country===countryExists[0]?.name
      })
      setFilterData(filteredDataa)

    },[filterDataFull,country])

    console.log(filterDataFull);
    console.log(filterData);

    
  return (
    <div className='product-review-container'>
       <div className='product-review-container-first'>
         {/* <img src={image} alt="" /> */}
         <div className='product-review-container-first-content'>
            <p className='flexible-plan'>Flexible plans & Pricing</p>
            <p className='flexible-plan-text'>EYE T offers a range of flexible plans and pricing options designed to accommodate businesses of all sizes. With customizable modules, scalable pricing tiers, and transparent billing. Our basic plan offers essential features and functionalities to streamline your business operations at an affordable price point. Our enterprise plan is designed for larger organizations with complex operations and advanced requirements. </p>
            <div className='product-review-container-erp-type'>
                <div className='single-product-details' style={{borderColor:filterDataFull?.length !== undefined &&filterDataFull[0]?.hexCode}}>
                    <img src="" alt="" />
                    <p style={{color:filterDataFull?.length !== undefined &&filterDataFull[0]?.hexCode}}>{data?.packageName}</p>
                </div>
            </div>
         </div>
        
       </div>
       <div className='product-review-container-second'>
        
             {
              filterDataFull?.length !== undefined && filterDataFull?.length>0 && data?.planType !== "Basic" &&
                <div className='register-customer-container-third-single plan-card'>
                <div className='register-customer-container-third-single-top'>
                     <p className='register-customer-card-std'>{filterDataFull?.length !== undefined && filterDataFull[0]?.planTypeName}</p>
                     <p className='register-customer-card-std-price'>{filterDataFull?.length !== undefined && filterDataFull[0]?.sellingPrice} / {filterDataFull?.length !== undefined &&  filterDataFull[0]?.durationType}</p>
                </div>
                <div className='register-customer-container-third-single-bottom'>
                    {
                        filterDataFull?.length !== undefined && filterDataFull[0]?.additionalInfo?.map((r,i)=>(
                            <div className='register-customer-card-icon-label'>
                              {
                                 r?.icon==="1"?
                                 <div>
                                   <div className='register-customer-card-icon' style={{backgroundColor:"#fbc9bd"}}>
                                    <i class="bi bi-check2" style={{color:"#f2492c"}}></i>
                                   </div>
                                 </div>
                                 :
                                 r?.icon==="2"?
                                 <div className='register-customer-card-icon'>
                                 <i class="bi bi-check2" style={{color:"blue"}}></i>
                                 </div>
                                 :
                                 r?.icon==="3"?
                                 <i class="bi bi-check-circle check-circle"></i>
                                 :
                                 r?.icon==="4"?
                                 <i class="bi bi-star-fill star-fill"></i>
                                 :
                                 r?.icon==="5"?
                                <i class="bi bi-check2 check2"></i>
                                 :undefined
                              }
                            
                             <p>{r?.content}</p>
                            </div>
                        ))
                    }
                   
                   <button onClick={addToCartFunction(
                    filterDataFull?.length !== undefined && filterDataFull[0]?._id,
                    filterDataFull?.length !== undefined && filterDataFull[0]?.isPurchase,
                    )}>PURCHASE</button>
                </div>
                </div>
            }
            {
              filterDataFull?.length !== undefined && filterDataFull?.length>1 &&
                <div className='register-customer-container-third-single plan-card'>
                <div className='register-customer-container-third-single-top platinum-card-top'>
                <p className='register-customer-card-std'>{filterDataFull?.length !== undefined && filterDataFull[1]?.planTypeName}</p>
                     <p className='register-customer-card-std-price'>{filterDataFull?.length !== undefined && filterDataFull[1]?.sellingPrice} / {filterDataFull?.length !== undefined &&  filterDataFull[1]?.durationType}</p>
                </div>
                <div className='register-customer-container-third-single-bottom platinum-card-bottom'>
                {
                        filterDataFull?.length !== undefined && filterDataFull[1]?.additionalInfo?.map((r,i)=>(
                            <div className='register-customer-card-icon-label'>
                                {
                                 r?.icon==="1"?
                                 <div>
                                   <div className='register-customer-card-icon' style={{backgroundColor:"#fbc9bd"}}>
                                    <i class="bi bi-check2" style={{color:"#f2492c"}}></i>
                                   </div>
                                 </div>
                                 :
                                 r?.icon==="2"?
                                 <div className='register-customer-card-icon'>
                                 <i class="bi bi-check2" style={{color:"blue"}}></i>
                                 </div>
                                 :
                                 r?.icon==="3"?
                                 <i class="bi bi-check-circle check-circle"></i>
                                 :
                                 r?.icon==="4"?
                                 <i class="bi bi-star-fill star-fill"></i>
                                 :
                                 r?.icon==="5"?
                                <i class="bi bi-check2 check2"></i>
                                 :undefined
                              }
                            
                             <p>{r?.content}</p>
                            </div>
                        ))
                    }
                  
                   
                    
                  
                   
                   
                    <button onClick={
                        addToCartFunction(
                          filterDataFull?.length !== undefined && filterDataFull[1]?._id,
                          filterDataFull?.length !== undefined && filterDataFull[1]?.isPurchase,
                        )
                        } >PURCHASE</button>
                </div>
                </div>
             }
          
           
          
       </div>
    </div>
  )
}
