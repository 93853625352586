import axios from "axios"
import { HEADERS, HEADER_QUERY } from "../UrlAndPaths"
import store from "../../Redux/store";
import { get_cart_list } from "../../Redux/Package/packageSlice";


// add cart api call
export const cartAPICall=(body,updateListner)=>{
    axios.post("customer/addcart",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
           console.log('add');
           updateListner()
        //    window.location.reload()
        // window.location.reload(false);
        }
    })
    .then((err)=>{
        console.log(err.response);
    })
}
// view cart api call
export const viewCartAPICall=(body)=>{
    axios.post("customer/viewcart",body,HEADERS)
    .then((res)=>{
       if(res.status===200){
         store.dispatch(get_cart_list({cartData:res.data}))
       }
    })
    .catch((err)=>{
        console.log(err.response);
    })
}
// delete cart api call
export const deleteCartApiCall=(body,updateListner)=>{
    axios.delete("customer/deletecart",{
        headers:HEADER_QUERY,
        data:body
    })
    .then((res)=>{
        if(res.status===200){
            console.log('deleted');
            updateListner()
        }
    })
    .catch((err)=>{
        console.log(err.response);
    })
}
//delete cart by customer id api call
export const deleteCartByCusIdApiCall=(updateListner)=>{
    axios.delete("customer/deletecartbycusId")
    .then((res)=>{
        if(res.status===200){
            console.log('delete all');
            updateListner()
        }
    })
    .catch((err)=>{
        console.log(err.response);
    })
}