import React, { useEffect, useState } from "react";
import TopBar from "../../TopBar";
import "../../../Css/Home/home.css";
import ContainedButton from "../../Custom Components/ContainedButton";
import BoyImg from "../../../Assets/Images/New/Group 11001.png";
import "aos/dist/aos.css";
import Aos from "aos";
import HotelSvg from "../../../Assets/Icons/hotel_1_.svg";
import EducationSvg from "../../../Assets/Icons/mortarboard.svg";
import RestaurantSvg from "../../../Assets/Icons/Restuarent.svg";
import AviationSvg from "../../../Assets/Icons/delivery_1_.svg"
import hospitalIcon from "../../../Assets/Icons/hospital_1_.svg";
import superMarket from "../../../Assets/Icons/superMarket.svg";
import { motion } from "framer-motion";
import { Footer } from "../../Footer";
import { useNavigate } from "react-router-dom";
import { HEADERS } from "../../../API/UrlAndPaths";
import { getUserInfoAPICall } from "../../../API/Login/Profile/profileInfoAPICall";
import { useSelector } from "react-redux";
import AccountingIcon from "../../../Assets/Icons/accountingIcon.svg"
import HrmIcon from "../../../Assets/Icons/hrmPayroll.svg"
import InventoryIcon from "../../../Assets/Icons/inventoryIcon.svg"
import PointOfSalesIcon from "../../../Assets/Icons/pointOfSaleIcon.svg"
import { today } from "../../JS/Date";
import { customerQueryAPICall } from "../../../API/Demo/demoAPI";
import SuccessSnackBar from "../../Custom Components/SuccessSnackBar";
import ErrorSnackBar from "../../Custom Components/ErrorSnackBar";
import { Button, IconButton } from "@mui/material";
import { geAllPackageForCustomerWebsite, packageAPICall } from "../../../API/Package/packageAPI";
import jwtDecode from "jwt-decode";
import { cartAPICall } from "../../../API/Cart/cartAPI";
import YourVideo from "../../../Assets/video/EYET erp.mp4"
import { alertMessageFn, getPrevCountryAPICall, warningAlertMessage } from "../../JS/alertFunction";
import { geoLocationAPICall } from "../../../API/Login/geoLocationAPI";
import landingPage from "../../../Assets/Images/New/Group 11055 (1).svg"
import getInTouch from "../../../Assets/Icons/New/Animation - 1707736092237 (1).gif"
import getInTouchBackground from "../../../Assets/Images/New/Group 10996 (2).png"
import usber from "../../../Assets/Images/New/MEET OUR CLIENTS 01.png"
import google from "../../../Assets/Images/New/MEET OUR CLIENTS 02.png"
import paypal from "../../../Assets/Images/New/MEET OUR CLIENTS 03.png"
import microsoft from "../../../Assets/Images/New/MEET OUR CLIENTS 04.png"
import dribbble from "../../../Assets/Images/New/MEET OUR CLIENTS 05.png"
import reviewimage from "../../../Assets/Images/New/download (4).jpg"
import wheredoesImg from "../../../Assets/Images/New/Where does it come from 2.png"
import wheredoesImg2 from "../../../Assets/Images/New/Where does it come from.png"
import fb from "../../../Assets/Images/New/FacebookG.svg"
import x from "../../../Assets/Images/New/XG.svg"
import git from "../../../Assets/Images/New/GitG.svg"
import instagram from "../../../Assets/Images/New/InstaG.svg"
import linkdin from "../../../Assets/Images/New/LinkedinG.svg"
import findErp from "../../../Assets/Images/New/Find your ERP Product.png"
import easyPurchase from "../../../Assets/Images/New/Easy Purchase.png"
import tracksale from "../../../Assets/Images/New/Track Sales Transaction.png"
import path1 from "../../../Assets/Images/New/Group 10370.png"
import path2 from "../../../Assets/Images/New/Group 10365.png"
import path3 from "../../../Assets/Images/New/Path 2700 (4).png"
import pinkBackground from "../../../Assets/Images/New/Mask Group 5.png"
import grayBackground from "../../../Assets/Images/New/Mask Group 4 (2).png"
import { useRef } from "react";

import TextileSvg from "../../../Assets/Icons/sewing-machine_2_.svg"
import one from "../../../Assets/Images/New/01.png"
import two from "../../../Assets/Images/New/02.png"
import three from "../../../Assets/Images/New/03.png"
import four from "../../../Assets/Images/New/04.png"
import Typewriter from 'typewriter-effect';

import Lottie from 'react-lottie';
import yellowSatrts from "../../../Assets/JSON/Animation - Yellow 3 Stars.json"
import arrowup from "../../../Assets/JSON/arrow-up-right.json"
import graph from "../../../Assets/JSON/Graph animation.json"
import settingsGreenRotating from "../../../Assets/JSON/Settings Green rotating.json"
import settingsGreen from "../../../Assets/JSON/Settings Green.json"
import { LandingPageImage } from "./LandingPageImage";
import { HashLink } from "react-router-hash-link";
import whitehouseLogo from "../../../Assets/Images/New/whitehouse-WHITE HOUSE (2).jpeg"
import albarzalogo from "../../../Assets/Images/New/albarzahtailor-barzhlogo.png"
import automac from "../../../Assets/Images/New/automacae-AUTOMACBkrem.png"
import laptop from "../../../Assets/Images/New/Group 11157.png"
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import countryList from "../../../Assets/JSON/countryListWithCode.json"
const Home = (props) => {
  const country=localStorage.getItem("country")
  const { t } = useTranslation();
  const scrollRef = useRef(null);
  const {setIsRendered,isRendered}=props
  let navigate = useNavigate();
  // const products=useSelector((state)=>state.packageList.value)
  const packageForCustomer=useSelector((state)=>state.packageList.packageCustomer)
  const cartData=useSelector((state)=>state.packageList.cartValue)



  let companyCode = localStorage.getItem("companyCode")
  let editSkipped = localStorage.getItem("editSkipped")
  const userInfo = useSelector((state) => state.profileInfoSlice.value)

  const [productDetailsOpen,setProductDetailsOpen]=useState(false)
  const [singleProductDetails,setSingleProductDetails]=useState([])
  const[isLoading,setIsLoading]=useState(false)
  const [isModify,setIsModify]=useState(false)
  const currentCounry=useSelector((state)=>state.locationList.value)
  const [products,setProducts]=useState([])
 

//   const products = [
//     {
//         content: "Product 1",
       
//     },
//     {
//         content: "Product 2",
       
//     },
//     {
//         content: "Product 3",
       
//     },
//     {
//         content: "Product 4",
      
//     },
//     {
//         content: "Product 5",
       
//     },
//     {
//       content: "Product 6",
     
//   },
//   {
//     content: "Product 7",
   
// },
// {
//   content: "Product 8",
 
// },
//     // Add more products as needed
// ];

  // Calculate the positions of inside circles
  const insideCirclePositions = [
    { top: -50, left: '50%', transform: 'translateX(-50%)' },
    { bottom: -60, left: '50%', transform: 'translateX(-50%)' },
    { left: -35, top: '50%', transform: 'translateY(-50%)' },
    { right:-35, top: '50%', transform: 'translateY(-50%)' },
  ];

  const insidePathPositions=[
    { bottom: 10, left: '36%', transform: 'translateX(-50%)' },
    { bottom: 16, left: '0%', transform: 'translateX(-50%)' },
    { bottom: 20, left: '80%', transform: 'translateX(-50%)' },
    // { right:-35, top: '70%', transform: 'translateY(-50%)' },
  ]
  //TODO Make cards horizontally scrollable
  const scrollToLeft = (icon) => () => {
    const scrollDiv = document.getElementById("purchaseProductCardContainer");
    const scrollFeatureDiv=document.getElementById("purchaseFeaturesContainer")
    const scrollReviewDiv=document.getElementById("reviewCardSection")
    if (icon === "left") {
      scrollDiv.scrollBy(-350, 0)
    }
    if (icon === "right") {
      scrollDiv.scrollBy(350, 0)
    }
    if (icon === "featureleft") {
      scrollFeatureDiv.scrollBy(-350, 0)
    }
    if (icon === "featureright") {
      scrollFeatureDiv.scrollBy(350, 0)
    }
    if(icon === "reviewLeft"){
      scrollReviewDiv.scrollBy(-350, 0)
    }
    if(icon === "reviewRight"){
      scrollReviewDiv.scrollBy(350, 0)
    }
  };

  const updateListner=()=>{
    setIsModify(!isModify)
    setIsRendered(!isRendered)
   }
  const clickDemo = () => {
    navigate('/freeTrial')
  }
  const moveToEditProfile = () => {
    navigate('/profile')
  }



  useEffect(() => {

    if (editSkipped === "true") {
      console.log("something");
    }
    else {
      if (userInfo !== undefined) {
        userInfo.info === undefined ? moveToEditProfile() : localStorage.setItem("editSkipped", true)
      }
    }
  }, [userInfo])
  const [snackBarStates,setSnackBarStates]=useState({
    success:false,
    error:false,
    snackMsg:"",
  })

  const handleCloseSuccess=()=>()=>{
      setSnackBarStates({...snackBarStates,error:false,success:false})
  }
  // purchse button click function
  const purchseBtnFn = () => {
    navigate('/products')
  }
  // view more button function
  const viewMoreBtnFn = () => {
    navigate('/products')
  }
 
  
  const queryFormInitial={
    name:"",
    lastName:"",
    email:"",
    mobile:"",
    message:""
  }
  const [queryForm,setQueryForm]=useState(queryFormInitial)


  const clearFn=()=>{
    setQueryForm(queryFormInitial)
  }
  const getQueryInfo=(key)=>(e)=>{
    const {value}=e.target;
    setQueryForm({...queryForm,[key]:value})
  }
  const submitSendMessage=()=>{
    setIsLoading(true)
    customerQueryAPICall(
      {
        name:queryForm.name,
        email:queryForm.email,
        mobile:queryForm.mobile,
        message:queryForm.message,
        date:today
      },clearFn,setSnackBarStates,setIsLoading
    )



  }

// my plans navigation function
const myPlansNavigationFn=()=>{
  navigate("/profileinfomyplans");
}
const cartNavigationFn=()=>{
  navigate("/profileinfomycart");
}



const [singleProductItem,setSingleProductItem]=useState()
// click single product
const  clickSingleItem=(id)=>{
  console.log(id);
    let data = products?.filter((obj)=>{
       if(obj?._id===id){
        return obj
       }
    })
    setSingleProductItem(data !== undefined && data[0])
}



useEffect(()=>{
  // geoLocationAPICall()
  Aos.init({ duration: 700 });
 },[])

 useEffect(()=>{
  const countryExists = countryList.filter(item => item.code === country);
  if(currentCounry!==undefined){
    getUserInfoAPICall({companyCode:companyCode})
    packageAPICall()
    getPrevCountryAPICall()
    geAllPackageForCustomerWebsite({country:countryExists[0]?.name})
  }
 },[currentCounry,country])

 console.log(currentCounry);

 useEffect(()=>{
 
  if(packageForCustomer?.data !== undefined){
    const dataCopy = JSON.parse(JSON.stringify(packageForCustomer.data));
    const sortedArray = dataCopy.sort((a, b) => b.released - a.released);
    setProducts(sortedArray)
  }
  
},[packageForCustomer])

useEffect(()=>{
    if(products !== undefined){
      setSingleProductItem(products[0])
    }
},[products])






const transition= {duration:4,type:'spring'} 
const cards = [
    { id: 1, image:whitehouseLogo, title: 'White House',subContent:"Works like a charm. I like the ease of the app and would highly recommend purchasing their yearly package." },
    { id: 2, image:albarzalogo, title: 'Albarza',subContent:"Tailoring made easy. They have balanced the ERP very well and packed with powerful features" },
    { id: 3, image:automac, title: 'Auto Mac',subContent:'Easy to use, Minimal amount of clicks and very user friendly' },
    // { id: 4, title: 'Card 4',subContent:"Lorem Ipsum dummy", content: 'This is the content of card 4.' },
  ];

  
  document.addEventListener('DOMContentLoaded', function() {
    const container = document.getElementById('swipeContainer');
    let startX = null;
  
    container.addEventListener('touchstart', function(event) {
      startX = event.touches[0].clientX;
    });
  
    container.addEventListener('touchmove', function(event) {
      if (!startX) return;
      const currentX = event.touches[0].clientX;
      const diff = startX - currentX;
      container.scrollLeft += diff;
    });
  
    container.addEventListener('touchend', function(event) {
      startX = null;
    });
  });

  const addToCartBtnFn=(id,r)=>()=>{
    const isTokenExists = localStorage.getItem('userToken') !== null;
    const token=localStorage.getItem('userToken')
    const booyToApi={
       cusId:token !==null &&jwtDecode(token)?._id,
      //  cart:productDeetailsData?.filter((obj)=>obj?._id===id)
      cart:[{id:id}]
    }
    if(isTokenExists){
      if(cartData?.cart?.some(item => item._id === id)){
        // if(window.confirm("Alredy exist.Please go to Cart")===true){
        //   navigate("/profileinfomycart");
        //  }
        warningAlertMessage("Item Already Exist in the Cart!","Do You Want to Proceed to Cart?",cartNavigationFn,"cart")
    }
    else if(r?.isPurchase===true){
      // alert("already purchased")
      warningAlertMessage("Item Already Purchased!","Go To My Plans...",myPlansNavigationFn,"plan")
    }
    else{
      cartAPICall(booyToApi,updateListner)
    }
    }
    else{
      navigate("/signIn");
    }
  }

  const viewPlansFunction =(r)=>()=>{
    let data = {
      packageName:r?.packageName,
      durationType:r?.durationType
    }
    localStorage.setItem("data",JSON.stringify(data))
    navigate("/productreview");
  }

  const language=localStorage.getItem("ln")

  useEffect(()=>{
   const elementsToChange = document.querySelectorAll('p, h1, h2,span');
   elementsToChange.forEach((element) => {
     if (language === 'ar') {
       element.style.direction = 'rtl'; // Right-to-left text direction for Arabic language
     } else {
       element.style.direction = 'ltr'; // Left-to-right text direction for other languages
     }
   });
  },[language])

  const [isExpanded, setIsExpanded] = useState(true);

  // Function to toggle the animation state
  const toggleAnimation = () => {
    setIsExpanded((prev) => !prev);
  };

  // Start the animation loop when the component mounts
 useEffect(() => {
    const interval = setInterval(toggleAnimation, 3000); // Change the duration to control the animation speed
    return () => clearInterval(interval); // Clean up the interval when the component unmounts
  }, []); 


 


  return (
    <>
     
        <div className="home-conatiner-one">
           <div className="home-conatiner-one-left">
              {/* <h1>HANDLE BUSINESS WITH EASE</h1> */}
              <h1>{t('heading')}</h1>
              <p>{t('handleBusinessContent')}</p>
            <div className="home-conatiner-one-left-btn-container">
            
            <button onClick={purchseBtnFn} className="btn cta-contained-btn home-purchase-btn" style={{margin:"0 8px 0 0",height:"45px",width:"170px"}}>{t('PurchaseBtn')}</button>
            <button className="btn cta-outlined-btn home-purchase-btn" onClick={clickDemo} style={{height:"45px",width:"170px"}}>{t('scheduleDemoBtn')}</button>
          </div>
          <div className="home-conatiner-one-left-icon-container">
                        <a href="https://ms-my.facebook.com/LeEYETtechnohub/"><img src={fb} alt="" /></a>
                        <a href="https://twitter.com/LEEYE_T"><img src={x} alt="" /></a>
                        <a href=""> <img src={instagram} alt="" /></a>
                        <a href="https://www.instagram.com/leeyet1/"><img src={git} alt="" /></a>
                        <a href=""><img src={linkdin} alt="" /></a>
          </div>
           </div>
           <div className="home-conatiner-one-right">
               
              {/* <img src={landingPage} alt="" /> */}
              {/* <LandingPageImage/> */}
               <LandingPageImage/>
             
             
              
           </div>
        </div>
        <div className="home-conatiner-two">
           <div className="home-conatiner-two-left">
              <img src={BoyImg} alt="Girl with laptop" />
           </div>
           <div className="home-conatiner-two-right">
           {
              language === 'ar'?
              <h2 style={{display:"flex"}}>{t('Bringing')}</h2>
              :
              <h2>{t('Bringing')}
              <Typewriter
                     //   onInit={(typewriter)=>{
                     //     for(let i=0; i<120; i++){ 
                     //         typewriter
                     //         .typeString(t('Bringing'))
                     //         .pauseFor(2500)
                     //         .deleteAll(100)
                     //         .start();
                     //     }
                     // }} 
                     options={{
                       strings: [t('Bringing')],
                       autoStart: true,
                       loop: true,
                       delay: 100, // Delay before starting typing
                       deleteSpeed: 50, // Speed of deleting characters
                       // reverse: true,
                       
                     }}
                     
                     >
                       
               </Typewriter>
             </h2>
            }
            <h2 style={{display:language === 'ar'&& "flex"}} className="home-conatiner-two-right-in-erp">{t('intheErpWorld')}</h2>
            <div style={{display:"flex",direction:language === 'ar'?"rtl":"ltr"}}>
              <i class="bi bi-lightbulb"></i>
              <p style={{margin:"0 0 0 4px"}}>{t('intheErpWorld1')}</p>
            </div>
            <div style={{display:"flex",direction:language === 'ar'?"rtl":"ltr"}}>
              <i class="bi bi-lightbulb"></i>
              <p style={{margin:"0 0 0 4px"}}>{t('intheErpWorld2')}</p>
            </div>
            <div style={{display:"flex",direction:language === 'ar'?"rtl":"ltr"}}>
              <i class="bi bi-lightbulb"></i>
              <p style={{margin:"0 0 0 4px"}}>{t('intheErpWorld3')}</p>
            </div>
            <div style={{display:"flex",direction:language === 'ar'?"rtl":"ltr"}}>
              <i class="bi bi-lightbulb"></i>
              <p style={{margin:"0 0 0 4px"}}>{t('intheErpWorld4')}</p>
            </div>
            <div style={{display:"flex",direction:language === 'ar'?"rtl":"ltr"}}>
              <i class="bi bi-lightbulb"></i>
              <p style={{margin:"0 0 0 4px"}}>{t('intheErpWorld5')}</p>
            </div>
           </div>
           
        </div>
        <div className="home-conatiner-three">
           <div className="home-conatiner-three-icon">
                <img  
               
                  src={findErp} alt="" />
                <img src={easyPurchase} alt="" />
                <img src={tracksale} alt="" />
           </div>
           <div className="home-conatiner-three-border">
              <div className="home-conatiner-three-border-line"></div>
              <div className="home-conatiner-three-border-line-round" style={{top:"50%",left:"16%"}}></div>
              <div className="home-conatiner-three-border-line-round" style={{top:"50%",left:"49.5%"}}></div>
              <div className="home-conatiner-three-border-line-round" style={{top:"50%",right:"16%"}}></div>
              
           </div>
           <div className="home-conatiner-three-border-content">
             <div className="home-conatiner-three-border-content-single">
               <h5>{t('finderp')}</h5>
               <p>{t('finderpdes')}</p>
             </div>
             <div className="home-conatiner-three-border-content-single">
              <h5>{t('easypurchase')}</h5>
              <p>{t('easypurchaseDes')}</p>
             </div>
             <div className="home-conatiner-three-border-content-single">
              <h5>{t('tracksale')}</h5>
              <p>{t('tracksaledes')}</p>
             </div>
             <motion.img    
                  animate={{ x: ['0%', '5%', '-5%', '0%'] }}
                  transition={{ loop: Infinity, duration: 3, ease: 'linear' }}
                  spring={{ type: 'spring', stiffness: 100, damping: 10 }}
                  src={path1} alt="" style={{position:"absolute",right:"28%",top:"16%",width:"10%"}}/>
             <motion.img 
              animate={{ x: ['0%', '5%', '-5%', '0%'] }}
              transition={{ loop: Infinity, duration: 3, ease: 'linear' }}
              spring={{ type: 'spring', stiffness: 100, damping: 10 }}
             src={path2} alt="" style={{position:"absolute",left:"28%",width:"10%"}} />
           </div>
        </div>
        <div className="home-conatiner-three-mobile" data-aos="fade-down" data-aos-duration="1500">
             <div className="home-conatiner-three-mobile-single">
               <h5>{t('finderp')}</h5>
               <p>{t('finderpdes')}</p>
             </div>
             <div className="home-conatiner-three-mobile-single">
              <h5>{t('easypurchase')}</h5>
              <p>{t('easypurchaseDes')}</p>
             </div>
             <div className="home-conatiner-three-mobile-single">
              <h5>{t('tracksale')}</h5>
              <p>{t('tracksaledes')}</p>
             </div>
                <div className="home-conatiner-three-border-line-round" style={{top:"3%",left:"-2%"}}></div>
              <div className="home-conatiner-three-border-line-round" style={{top:"40%",left:"-2%"}}></div>
              <div className="home-conatiner-three-border-line-round" style={{top:"68%",left:"-2%"}}></div>
        </div>
        {/* container four */}
        <div className="home-conatiner-four" data-aos="fade-down" data-aos-duration="1500">
          <div className="home-conatiner-four-content">
              <p className="home-conatiner-four-content-first-p">{t('unlash')}</p>
              <h1 className="home-conatiner-four-content-second-p">{t('discover')}</h1>
              <p className="home-conatiner-four-content-third-p">{t('discoverdes')}</p>
          </div>
          
             <motion.img 
              animate={{ y: [0, -20, 0] }}
              transition={{ loop: Infinity, duration: 4,type: 'spring' }}
              src={wheredoesImg2} alt="" className="pink-background-image" />
        
           
        </div>

        {/* container five */}


        <div className="container-five" >
            <div className="circle-container">
            <motion.div 
            className="dashed-circle">
              {
                singleProductItem !== undefined &&
                <>
                    <p className="round-first-p" style={{color:singleProductItem?.hexCode}}>{singleProductItem?.packageName}</p>
                    {/* <p className="round-second-p" style={{color:singleProductItem?.hexCode}}>{singleProductItem?.sellingPrice} / {singleProductItem?.durationType}</p> */}
                    <p className="round-third-p">{singleProductItem?.description}</p>
                    {
                      singleProductItem?.released?
                      <button onClick={viewPlansFunction(singleProductItem)} className="round-outline-btn" style={{color:singleProductItem?.hexCode,borderColor:singleProductItem?.hexCode,cursor:"pointer"}} >View Plans</button>
                     :
                     <div>
                     <p style={{margin:"0 0 10px 0",color:singleProductItem?.hexCode,fontWeight:"600"}}>COMING SOON</p>
                     <motion.hr 
                     initial={{ width: '100%' }}
                     animate={{ width: isExpanded ? '2%' : '100%' }}
                     transition={{ duration: 3, loop: Infinity }}
                     style={{backgroundColor:singleProductItem?.hexCode,border:"none",height:"2px",
                             position: 'relative',
                             left: '50%',
                             transform: 'translateX(-50%)',}} 
                     className='coming-soon-hr'/>
                   </div>
                    }
                </>
              }
                 
          {products?.slice(0, 4).map((item, index) => (
            <div onClick={()=>clickSingleItem(item?._id)} key={index} className="small-circle inside-circle" style={insideCirclePositions[index]}>
                <motion.div 
                   whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}
                   className="icon-circle" style={{backgroundColor:item?.hexCode}}>
                  <img src={`data:name;base64,${item?.logo?.data}`} alt="" />
                </motion.div>
                <p style={{color:item?.hexCode}}>{item?.packageName}</p>
            </div>
          ))}
            </motion.div>
            <div className="horizontal-path" style={{display:"none"}}>
           <motion.svg 
             animate={{ x: ['2%', '4%',] }}
             transition={{ loop: Infinity, duration: 3, ease: 'linear' }}
             spring={{ type: 'spring', stiffness: 100, damping: 10 }}
           viewBox="0 0 1800 200" height={200} width="100%">
  <path d="M 0,100 
           C 150,250 350,0 600,100 
           C 900,250 1200,0 1500,100 
           C 1600,300 1700,50 1800,100 
           C 1900,50 2000,200 2200,100"  
    stroke="#afacac" 
    strokeWidth="1" 
    fill="none" 
    strokeDasharray="5,5" 
  />
           </motion.svg>
      
            </div>
            <div className="path-container" style={{display:"none"}}>
        {products?.slice(4, 7).map((item, index) => (
      <div 
         onClick={()=>clickSingleItem(item?._id)}  key={index} className="small-circle path-circle" style={insidePathPositions[index]}>
        <motion.div 
           whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}
           className="icon-circle" style={{ backgroundColor: item.hexCode }}>
          <img src={`data:name;base64,${item?.logo?.data}`} alt="" />
        </motion.div>
        <p>{item?.packageName}</p>
      </div>
    ))}
            </div>
            </div>
            <img src={grayBackground} alt="" className="product-gray-image"/>
            <div className="purchase-product-heading">
              <h1>Purchase</h1>
              <h1 style={{marginLeft:"18%"}}>Products</h1>
            </div>
            <div className="view-all-products-button">
              
              
              
                   <HashLink smooth to="/products#top" style={{color:"green",textDecoration:"none"}}>
                   <motion.button whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}>

                 
                      VIEW ALL PRODUCTS <i class="bi bi-arrow-up-right"></i>
                      </motion.button>
                  </HashLink>
                 
                
            </div>
        </div>

        <div class="container-mobile-view" id="swipeContainer" data-aos="fade-down" data-aos-duration="1500">
          <div class="container-mobile-view-inner">
    {
      products?.map((item)=>(
        <div class="container-mobile-view-single-card">
        <div style={{display:"flex",alignItems:"center"}}>
             <motion.div 
                   whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}
                   style={{backgroundColor:item?.hexCode,width:"60px",height:"60px",display:"flex",justifyContent:"center",alignItems:"center",borderTopLeftRadius:"12px",borderBottomRightRadius:"12px"}}>
                  <img src={`data:name;base64,${item?.logo?.data}`} alt="" style={{width:"80%"}} />
                </motion.div>
          <p style={{marginLeft:"12px",fontSize:"0.9rem",fontWeight:500,color:item?.hexCode}}>{item?.packageName}</p>
        </div>
        <div style={{display:"flex",justifyContent:"center",alignItems:"center",padding:"4%"}}>
          <p style={{fontSize:"0.8rem",fontWeight:600,margin:0}}>{item?.description}</p>
        </div>
        <button onClick={viewPlansFunction(item)} style={{backgroundColor:item?.hexCode}}>view Plans</button>
       </div>
      ))
    }
   
   
          </div>
        </div>



      
        <div className="home-conatiner-six" data-aos="fade-down" data-aos-duration="1500">
          <div className="home-conatiner-six-text">
                  <h2>{t('turnInfo')}</h2>
                  <h1>{t('erpfeature')}</h1>
          </div>
          <motion.div className="home-conatiner-six-card" >
            <div  className="home-conatiner-six-card-image">
              <img src={InventoryIcon} alt="" /> 
            </div>
            
            <div 
            className="home-conatiner-six-card-text">
              <h4>{t('inventory')}</h4>
              <p>{t('inventorydes')}</p>
            </div>
          </motion.div>
          <div className="home-conatiner-six-card" style={{left:0,top:"30%"}}>
            <div  className="home-conatiner-six-card-image" style={{backgroundColor:"#2aab11",boxShadow:"0 0 10px 0px #2aab11"}}>
              <img src={PointOfSalesIcon} alt="" /> 
            </div>
            
            <div className="home-conatiner-six-card-text">
              <h4>{t('pos')}</h4>
              <p>{t('posdes')}</p>
            </div>
          </div>
          <div className="home-conatiner-six-card" style={{right:0,top:"50%"}}>
            <div  className="home-conatiner-six-card-image" style={{backgroundColor:"#004cff",boxShadow:"0 0 10px 0px #004cff"}}>
              <img src={HrmIcon} alt="" /> 
            </div>
            
            <div className="home-conatiner-six-card-text">
              <h4>{t('hrm')}</h4>
              <p>{t('hrmdes')}</p>
            </div>
          </div>
          <div className="home-conatiner-six-card" style={{left:0,top:"75%"}}>
            <div  className="home-conatiner-six-card-image" style={{backgroundColor:"#7700ff",boxShadow:"0 0 10px 0px #7700ff"}}>
              <img src={AccountingIcon} alt="" /> 
            </div>
            
            <div className="home-conatiner-six-card-text">
              <h4>{t('accounting')}</h4>
              <p>{t('accountingdes')}</p>
            </div>
          </div>
           <motion.img  
                 initial={{top:'-10%'}}
                 whileInView={{top:'18%'}}
                 transition={transition}
                 src={one} alt="" style={{position:"absolute",right:"38%",top:"20%",zIndex:-1,width:"12%"}}
            />
          <motion.img 
              initial={{top:'10%'}}
              whileInView={{top:'48%'}}
              transition={transition}

             src={two} alt="" style={{position:"absolute",left:"25%",top:"48%",zIndex:-1,width:"12%"}}/>
          <motion.img 
             initial={{top:'50%'}}
             whileInView={{top:'68%'}}
             transition={transition}
             src={three} alt="" style={{position:"absolute",right:"-4%",top:"68%",zIndex:-1,width:"12%"}}/>
           
            <motion.img 
              initial={{top:'60%'}}
              whileInView={{top:'85%'}}
              transition={transition}
               src={four} alt="" style={{position:"absolute",left:"39%",top:"85%",zIndex:-1,width:"12%"}}/>
            {/* <div className="home-conatiner-six-first">
               <div className="home-conatiner-six-first-left">
                  <h2>Turn Information</h2>
                  <h1>Our ERP Features</h1>
               </div>
               <div className="home-conatiner-six-first-right"></div>
            </div>
            <div>2</div>
            <div>3</div>
            <div>4</div> */}
        </div>
        <div className="home-conatiner-seven" data-aos="fade-down" data-aos-duration="1500">
          <div className="home-conatiner-seven-content">
             <p className="where-does-p">{t('elevateyr')}</p>
             <p className="where-does-content-p">{t('elevateyrdes')}</p>
          </div>
          
              
                <motion.img 
                  animate={{ y: [0, -50, 0] }}
                  transition={{ loop: Infinity, duration: 4,type: 'spring' }}
                src={wheredoesImg} alt="" />
              
        </div> 
        <div className="home-conatiner-eight" >
            <p style={{fontSize:"1.1rem",fontWeight:700,margin:0}}>{t('checkout')}</p>
            <p style={{fontSize:"2.1rem",margin:"8px 0"}}>{t('learn')}</p>
            <div className="home-conatiner-eight-video">
            <ReactPlayer
            // url="https://www.example.com/video.mp4"
            url={YourVideo}
            controls
            width="100%"
            height="auto"
            autoplay
            muted 
      />
            </div>
        </div>
        <div className="home-conatiner-nine" data-aos="fade-down" data-aos-duration="1500" style={{display:"none"}}>
          <div 
          id="reviewSection"
          className="home-conatiner-nine-card-container">
             <p style={{display:"flex",justifyContent:"center",margin:"2%",fontSize:"1.3rem",fontWeight:600,fontFamily:"revert"}}>TESTIMONIALS</p>
             <div
             
               id="reviewCardSection" 
               className="home-conatiner-nine-card-single"
               >
                 {cards.map((card, index) => (
                <motion.div 
                 whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}
                 key={card.id}
                 className='review-card-sigle'
                
                 
                >

                  <div className="review-card-sigle-image">
                   <img src={card?.image} alt="" />
                  </div>
                  <h4>{card.title}</h4>
                  <div>
                     <p className="card-content">
                      {card?.subContent}
                      </p>
                  </div>
                 
                  {/* <p>{card?.content}</p> */}
               </motion.div>
               ))}

             </div>
          
          
             <div className="home-conatiner-nine-icon">
             <IconButton  onClick={scrollToLeft("reviewLeft")} className="home-conatiner-nine-icon-single"><i class="bi bi-chevron-left"></i></IconButton>
             <IconButton  onClick={scrollToLeft("reviewRight")} className="home-conatiner-nine-icon-single"><i class="bi bi-chevron-right"></i></IconButton>
            </div>
          </div>
        
         
          <div className="home-conatiner-nine-icon" style={{margin:"0% auto"}}><p style={{fontSize:"1.4rem",fontWeight:600}}>MEET OUR CLIENTS</p></div>
          <div className="home-conatiner-nine-icon" style={{justifyContent:"space-evenly"}}>
             <motion.img 
                  src={whitehouseLogo} alt=""  
                  animate={{ y: [-20, 5, 5, -20, ] }}
                  transition={{loop: Infinity,duration:4,type:'spring' }}
                  // style={{width:"10%"}}
                  className="clients-log"
                  />
             <motion.img 
                src={albarzalogo} alt=""
                animate={{ y: [20, 5,5,20] }}
                transition={{loop: Infinity,duration:4,type:'spring' }}
                // style={{width:"10%"}}
                className="clients-log"
                />
              
             <motion.img 
               src={automac} alt="" 
               animate={{ y: [-20,5,5, -20] }}
               transition={{loop: Infinity,duration:4,type:'spring' }}
              //  style={{width:"10%"}}
              className="clients-log"
               />
             {/* <motion.img src={microsoft} alt=""
              animate={{ y: [20, 5,5,20] }}
              transition={{loop: Infinity,duration:4,type:'spring' }}
              style={{width:"13%"}}
             />
             <motion.img src={dribbble} alt=""
                animate={{ y: [-20,5,5, -20] }}
                transition={{loop: Infinity,duration:4,type: 'spring' }}
                style={{width:"13%"}}
             /> */}
          </div>
        </div>
        <div className="home-nine-container-new">
          <p className="grow-up-your-business">{t("growup")}</p>
          <p className="with-us">{t("withus")}</p>
          <img src={laptop} alt="" data-aos="fade-down" data-aos-duration="1500"/>
        </div>

        {/* contact us */}

        <div className="home-conatiner-ten" data-aos="fade-down" data-aos-duration="1500">
           <div className="ten-contact-info">
               <p>{t("contactus")}</p>
               <div className="ten-contact-info-content">
                <div className="ten-contact-info-content-left">
                   <p>{t("getintouch")}</p>
                   <p style={{fontSize:"0.8rem",color:"rgb(171 171 171)",fontWeight:500,lineHeight:"1.6"}}>
                    {t("getintouchdes")}
                   </p>
                        <img src={getInTouchBackground} alt=""  style={{position:"absolute",bottom:"13%",left:"30%"}}/>
                        <img src={getInTouch} alt="" style={{position:"absolute",bottom:"23%",left:"39%"}}/>
                </div>
                <div className="ten-contact-info-content-right">
                    <div className="ten-contact-info-content-righ-card" >
                        <div className="ten-contact-info-content-righ-card-input">
                          <p>{t("firstname")}</p>
                          <input 
                            type="text"
                            value={queryForm.name}
                            onChange={getQueryInfo("name")}
                           
                            />
                        </div>
                        <div className="ten-contact-info-content-righ-card-input">
                          <p>{t("lastname")}</p>
                          <input type="text" />
                        </div>
                        <div className="ten-contact-info-content-righ-card-input">
                          <p>{t("email")}</p>
                          <input 
                          type="text"
                          onChange={getQueryInfo("email")}
                          value={queryForm.email}
                          />
                        </div>
                        <div className="ten-contact-info-content-righ-card-input">
                          <p>{t("phone")}</p>
                          <input 
                            type="text"
                            onChange={getQueryInfo("mobile")}
                            value={queryForm.mobile}
                            />
                        </div>
                        <div className="ten-contact-info-content-righ-card-input">
                          <p>{t("message")}</p>
                          <textarea 
                             name="" id="" cols="30" rows="4"
                             onChange={getQueryInfo("message")}
                             value={queryForm.message}
                             ></textarea>
                        </div>
                        <div style={{display:"flex",justifyContent:"center",alignContent:"center",margin:"4% 0 0 0"}}>
                           <ContainedButton
                           label={t("submit")}
                          onClick={submitSendMessage}
                           width="30%"
                           marginLeft="0"
                         />
                        </div>
                      
                    </div>
                </div>
               </div>
           </div>
        </div>
    
    


    

      
   

     

      
      {/* Footer Section  */}
      <Footer />

      <SuccessSnackBar
            open={snackBarStates.success}
            handleClose={handleCloseSuccess()}
            message={snackBarStates.snackMsg}
         />
      <ErrorSnackBar
        open={snackBarStates.error}
        handleClose={handleCloseSuccess()}
        message={snackBarStates.snackMsg}
      />
    </>
  );
};

export default Home;
