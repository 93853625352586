import React, { useState,useEffect, useLayoutEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Home from './Components/Pages/Home/Home';
import { Products } from './Components/Pages/Products/Products';
import { Login } from './Components/Pages/Login/Login';
import { NewSignup } from './Components/Pages/Login/NewSignup';
import { ProfileInfo } from './Components/Pages/Profile/ProfileInfo';
import { ProfileInfoHome } from './Components/Pages/Profile/ProfileInfoHome';
import { ProfileInfomyPlans } from './Components/Pages/Profile/ProfileInfomyPlans';
import { ProfileInfoChangepass } from './Components/Pages/Profile/ProfileInfoChangepass';
import { ProfileInfoMycart } from './Components/Pages/Profile/ProfileInfoMycart';
import { FreeTrial } from './Components/Pages/Demo/FreeTrial';
import TopBar from './Components/TopBar';
import { ForgotPass } from './Components/Pages/Login/ForgotPass';
import '../src/Css/Custom Components/gitex.css'
import gitexImage from '../src/Assets/Images/GITEX copy.png'
import { alertMessageFn, getPrevCountryAPICall } from './Components/JS/alertFunction';
import { geoLocationAPICall } from './API/Login/geoLocationAPI';
import { useSelector } from 'react-redux';
import ingImage from "../src/Assets/Images/Web-View.png"
import mobileIngImage from "../src/Assets/Images/Mobile-View.png"
import { Qrcode } from './Components/Pages/Qrcode/Qrcode';
import { PaymentCheckoutPage } from './Components/Pages/Profile/PaymentCheckoutPage';
import { PaymentCheckoutProcessPage } from './Components/Pages/Profile/PaymentCheckoutProcessPage';
import { TopBarSelect } from './Components/TopBarSelect';
import { ProductReview } from './Components/Pages/Products/ProductReview';
import { Helmet } from 'react-helmet';


function ProtectedRoute({ element: Element, isAuthenticated, fallbackPath }) {
  return isAuthenticated ? (
    <Element />
  ) : (
    <Navigate to={fallbackPath} replace />
  );
}

function App() {
  
  const checkLogged = localStorage.getItem('isLogged') === 'true';

  const [isRendered,setIsRendered]=useState(false)

  // useEffect(() => {
  //   // This code will run when the component (page) is mounted
  //   if(window.confirm('Welcome to My React Website!')===true){
  //     window.location.replace('https://example.com');
  //   }// Customize the alert message
  // }, []); 

 


 

  // const [image,setImage]=useState(true)
  // useEffect(()=>{
  //    setTimeout(()=>{
  //      setImage(false)
  //    },[6000])
    
  // },[currentCounry])

 
  function getCookie(name) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1);
      }
    }
    return null; // Cookie not found
  }

  const prevCountry=useSelector((state)=>state.countryListSlice)
  const currentCounry=useSelector((state)=>state.locationList.value)
  const redirectedDomain = getCookie('redirectedDomain');
  console.log(redirectedDomain);

//   useEffect(()=>{
//     setTimeout(()=>{
//       if(currentCounry !== undefined && currentCounry?.country_name !=="India"){
//               alertMessageFn(currentCounry)
//                }
//        },[8000])
//  },[currentCounry])

 useEffect(()=>{
  geoLocationAPICall()
 },[])

 

 
  
  return (
    <BrowserRouter>
      <>
      {
      //  (image)?
      //  <div className='inaugration-image'>
      //    <img className='desktop-img' src={ingImage}></img>
      //    <img className='mobile-img' src={mobileIngImage}></img>
      //  </div>
       
      //  :
       <>
       <div style={{position:"relative"}}>
        <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-1JJ5XZREW2"></script>
      
        <script>
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-1JJ5XZREW2');
      `}
    </script>
           
        </Helmet>
       <TopBarSelect/>
       <TopBar isRendered={isRendered}/>
       <Routes>
         <Route exact path="/" element={<Home setIsRendered={setIsRendered} isRendered={isRendered}/>} />
         <Route path="/products" element={<Products setIsRendered={setIsRendered} isRendered={isRendered} />} />
         <Route path="/productreview" element={<ProductReview/>} />
         <Route path="/signIn" element={<Login />} />
         <Route path="/signup" element={<NewSignup />} />
         <Route path="/forgotpass" element={<ForgotPass />} />

         <Route
           path="/profile"
           element={
            //  <ProfileInfo/>
             <ProtectedRoute
               element={ProfileInfo} // Pass the component directly
               isAuthenticated={checkLogged}
               fallbackPath="/signIn"
              />
           }
         />

         <Route path="/profileinfohome" element={<ProfileInfoHome />} />

         <Route
           path="/profileinfomyplans"
           element={
            //  <ProfileInfomyPlans/>
             <ProtectedRoute
               element={ProfileInfomyPlans} // Pass the component directly
               isAuthenticated={checkLogged}
               fallbackPath="/signIn"
             />
           }
         />

         <Route
           path="/profileinfochangepass"
           element={
              //<ProfileInfoChangepass/>
             <ProtectedRoute
               element={ProfileInfoChangepass} // Pass the component directly
               isAuthenticated={checkLogged}
               fallbackPath="/signIn"
             />
           }
         />
         <Route path="/profileinfomycart" element={<ProfileInfoMycart />} />
         <Route path="profileinfomycart/checkout" element={<PaymentCheckoutPage />} />
         <Route path="profileinfomycart/checkout/process" element={<PaymentCheckoutProcessPage />} />
         <Route path="/freeTrial" element={<FreeTrial />} />
         <Route path='/qrcode' element={<Qrcode/>}/>

       </Routes>
       </div>
       {/* <div className='gitex-container'>
       <p className='gitex-container-first-p'>MEET US AT</p>
       <span className='gitex-container-second-p'><i class="bi bi-geo-alt-fill location-icon"></i>WORLD TRADE CENTER,DUBAI</span>
       <img src={gitexImage}></img>
       <p className='gitex-container-third-p'>16-20 OCT 2023</p>
       <p className='gitex-container-four-p' style={{marginTop:"8px"}}>HALL NO:H26</p>
       <p className='gitex-container-four-p'>C50-13</p>
       </div> */}
       </>  
      }
    
      </>
    </BrowserRouter>
  );
}

export default App;