import React, { useState } from 'react'
import '../../../Css/Profile/profileinfochangepass.css'
import lockImg from "../../../Assets/Images/New/Reset Password.png"
import { changePassAPICall } from '../../../API/Login/loginUserAPI'
import SuccessSnackBar from '../../Custom Components/SuccessSnackBar'
import ErrorSnackBar from '../../Custom Components/ErrorSnackBar'
import Button from '@mui/joy/Button';
export const ProfileInfoChangepass =() =>{

    const passwordFormInitialState={
        oldPassword:"",
        newPassword:"",
        newPasswordConfirm:"",
    }
    const [passwordForm,setPasswordForm]=useState(passwordFormInitialState)
     // snackbar state
    const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    const [snackMsg, setSnackMsg] = useState("oops!");

    const [isLoading,setIsLoading]=useState(false)
    const [oldPasswordAlert,setOldPasswordAlert]=useState(false)
    const [newPasswordAlert,setNewPasswordAlert]=useState(false)

    const getPasswordInfo=(key)=>(e)=>{
        const{value}=e.target
        if(key==="oldPassword"){
            setPasswordForm({...passwordForm,oldPassword:value})
            setOldPasswordAlert(false)
        }
        if(key==="newPassword"){
            setPasswordForm({...passwordForm,newPassword:value})
            setNewPasswordAlert(false)
        }
        if(key==="newPasswordConfirm"){
            setPasswordForm({...passwordForm,newPasswordConfirm:value})
            setNewPasswordAlert(false)
        }
    }
    const closeSuccessSnackbar=()=>{
        setOpenSuccessSnackbar(false)
      }
    const closeErrorSnackbar=()=>{
    setOpenErrorSnackbar(false)
    }

    const clearStates=()=>{
        setPasswordForm(passwordFormInitialState)
        setOldPasswordAlert(false)
    }
    const clickChangePass=()=>{
        if(passwordForm.oldPassword===""){
            setOldPasswordAlert(true)
        }
        else if(passwordForm.newPasswordConfirm===""){
            setNewPasswordAlert(true)
        }
        else if(passwordForm.newPasswordConfirm!==passwordForm.newPassword){
            setNewPasswordAlert(true)
        }
        else{
            setIsLoading(true)
            changePassAPICall({
                oldPassword:passwordForm.oldPassword,
                newPassword:passwordForm.newPasswordConfirm
            },setOpenSuccessSnackbar,
            setOpenErrorSnackbar,
            setSnackMsg,
            clearStates,
            setOldPasswordAlert,setIsLoading)
        }
    }

    return(
    <>
    <div className="change-pass-ctn">
        <div className="change-pass-ctn-right">
            <img src={lockImg} alt="" />
        </div>
        <div className="change-pass-ctn-left">            
            <div className="change-pass-head">
                <h2>Reset Your Password</h2>
                <p>Enter Your New Password for your account</p>
            </div>
            <div className="change-pass-body">
                <div className="change-pass-body-section">
                    <p>Old Password</p>
                    <input type="password" value={passwordForm?.oldPassword} onChange={getPasswordInfo("oldPassword")}/>
                    {oldPasswordAlert? 
                        <label className="custom-input-validation" style={{left:"0"}}>
                        Password incorrect !!</label>
                    :""}  
                </div>
                <div className="change-pass-body-section">
                    <p>New Password</p>
                    <input type="password" value={passwordForm?.newPassword} onChange={getPasswordInfo("newPassword")}/>
                </div>
                <div className="change-pass-body-section">
                    <p>Confirm New Password</p>
                    <input type="password" value={passwordForm?.newPasswordConfirm} onChange={getPasswordInfo("newPasswordConfirm")}/>                
                    {newPasswordAlert? 
                        <label className="custom-input-validation" style={{left:"0"}}>
                        Password doesn't matches !!</label>
                    :""}  
                </div>        
            </div>
            <div className="change-pass-btn">
                <Button onClick={clickChangePass} loading={isLoading}>
                    Change password
                </Button>
                {/* <button onClick={clickChangePass}> Change password</button> */}
            </div>
        </div>
       
    </div>    


    <SuccessSnackBar open={openSuccessSnackbar} handleClose={closeSuccessSnackbar} message={snackMsg}/>
    <ErrorSnackBar open={openErrorSnackbar} handleClose={closeErrorSnackbar} message={snackMsg}/>
    </>
    )
}