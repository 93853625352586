import React, { useEffect } from 'react'
import "../../../Css/Profile/profileinfomycart.css";
import RestaurantSvg from "../../../Assets/Icons/Restuarent.svg";
import { Autocomplete, Dialog, TextField } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { deletePackageAPICall, viewAllSmsPackageAPICall, viewAllWhatsappPackageAPICall } from '../../../API/Package/packageAPI';
import { today } from '../../JS/Date';
import emptyCart from "../../../Assets/video/emptycartImage.png"
import { PaymentCheckoutPage } from './PaymentCheckoutPage';
import { useNavigate } from 'react-router-dom';
import { PaymentCheckoutProcessPage } from './PaymentCheckoutProcessPage';
import { checksum, decrypt, encrypt } from './aes';
import { AddOrderApiCall, addCustomerPurchase, addCustomerPurchaseAPICall, addOutletAPICall, freeTrialAPICall, viewPlansSingleAPICall } from '../../../API/Payment/PaymentAPI';
import SuccessSnackBar from '../../Custom Components/SuccessSnackBar';
import ErrorSnackBar from '../../Custom Components/ErrorSnackBar';
import jwtDecode from "jwt-decode";
import { viewCartAPICall } from '../../../API/Cart/cartAPI';
import { freeTrialDurationAPICall } from '../../../API/Profile/myPlansAPICall';
import countryList from "../../../Assets/JSON/countryListWithCode.json"
import { Helmet } from "react-helmet";
export const ProfileInfoMycart = () => {
   const token = localStorage.getItem("userToken");
   const country=localStorage.getItem("country")
   const navigateTo=useNavigate()
   const currentCounry=useSelector((state)=>state.locationList.value)
   const cartData = useSelector((state) => state.packageList.cartValue);
   const smsPackage = useSelector((state) => state.getAllsmsPackageSlice.value);
   const whatsappPackage=useSelector((state)=>state.getAllsmsPackageSlice.whatsapp)
   const [cartFullList,setCartFullList]=useState([])
   const [checkedDataList,setCheckedDataList]=useState([])
   const [checkoutPage,setCheckoutPage]=useState(false)
   const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
   const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
   const [snackMsg, setSnackMsg] = useState("oops!");
   const [isModify, setIsModify] = useState(false);
   const [isFreeTrial,setIsFreeTrial]=useState(false)
   const singleViewPlan=localStorage.getItem("singleViewId")
   const myPlanSingleView=useSelector((state)=>state.myPlansSlice.singleData)
   const freeTrialDuration=useSelector((state)=>state.myPlansSlice.freeTrial)

   console.log(cartData);

   const amountStateInitial={
      subTotal:0,
      tax:0,
      grandToatl:0,
      totalPaid:0,
      balance:0,
      totalOutlet:0
   }
   const [amountState,setAmountState]=useState(amountStateInitial)

   const closeSuccessSnackbar=()=>{
      setOpenSuccessSnackbar(false)
    }
  
    const closeErrorSnackbar=()=>{
    setOpenErrorSnackbar(false)
    }

    const updateListner = () => {
      setIsModify(!isModify);
    };
  

   // delete button function
   const removeItemFromCart=(id)=>{
      deletePackageAPICall(cartData?._id,id,updateListner,setOpenSuccessSnackbar,setOpenErrorSnackbar,setSnackMsg)
   }

   const checkboxCheckFn = (index, key) => {
      const updatedList = [...cartFullList];
        updatedList[index] = {...updatedList[index],[key]: !updatedList[index][key]};
        setCartFullList(updatedList);
    };
     // sms package chane
  const smsPackageChange = (index) => (e, newValue) => {
   const updatedList = [...cartFullList];
   updatedList[index].smsPackage = newValue;
   updatedList[index].smsPackageStartDate = newValue?.STARTDATE;
   updatedList[index].smsPackageEndDate = newValue?.ENDDATE;
   setCartFullList(updatedList);
 };

 const whatsappPackageChange=(index)=>(e,newValue)=>{
   const updatedList = [...cartFullList];
  updatedList[index].whatsappPackage = newValue;
  updatedList[index].whatsappPackageStartDate = newValue?.STARTDATE;
  updatedList[index].whatsappPackageEndDate = newValue?.ENDDATE;
  setCartFullList(updatedList);
 }


    const incButtonFn = (index) => {
 
      
      const updatedPackageListCart = [...checkedDataList];
      updatedPackageListCart[index].outletCount++;
      updatedPackageListCart[index].outletAmount = updatedPackageListCart[index].outletCount * updatedPackageListCart[index].locationPrice;
      // updatedPackageListCart[index].orderTotal=
      // (updatedPackageListCart[index].outletAmount)+
      // (updatedPackageListCart[index].sellingPrice)+
      // (updatedPackageListCart[index].enableSmsPackage && updatedPackageListCart[index].smsPackage?.sellingtotal)+
      // (updatedPackageListCart[index].enableBackupPrice && updatedPackageListCart[index].backupPrice)
      setCheckedDataList(updatedPackageListCart);
    }
    const decButtonFn=(index)=>{
      const updatedPackageListCart = [...checkedDataList];
      updatedPackageListCart[index].outletCount--;
      updatedPackageListCart[index].outletAmount = updatedPackageListCart[index].outletCount * updatedPackageListCart[index].locationPrice;
      setCheckedDataList(updatedPackageListCart);
    }
     // calcualte total
     const calculateTotalAmount = (checkedDataList) => {
      const calculatedValues = checkedDataList?.map((item) => {
        if(item?.inclusive===true){
  
          let totalAmount = (item?.sellingPrice && parseFloat(item?.sellingPrice)) +
          parseFloat(item?.outletAmount) +
          (item?.enableBackupPrice === true && parseFloat(item?.backupPrice) || 0) +
          (item?.enableSmsPackage === true && parseFloat(item?.smsPackage?.sellingtotal) || 0) +
          (item?.enableWhatsappPackage === true && parseFloat(item?.whatsappPackage?.sellingtotal) || 0);
          let totalOutlet = item?.outletAmount;
          let taxArray = item?.taxDetails?.map((obj) => obj?.amount);
  
          let totalOfTax = taxArray.reduce((sum, item) => sum + item, 0);
          let taxableAmount = (totalAmount / (totalOfTax + 100)) * 100;
          let incTax =  parseFloat(totalAmount) - parseFloat(taxableAmount)
  
  
         return { ...item, totalAmount: totalAmount, totalOutlet: totalOutlet,inclusiveTax:incTax };
  
        }
        else{
          let totalAmount = (item?.sellingPrice ? parseFloat(item?.sellingPrice):0) +
          parseFloat(item?.outletAmount) +
          (item?.enableBackupPrice === true && parseFloat(item?.backupPrice) || 0) +
          (item?.enableSmsPackage === true && parseFloat(item?.smsPackage?.sellingtotal) || 0) +
          (item?.enableWhatsappPackage === true && parseFloat(item?.whatsappPackage?.sellingtotal) || 0);
          let totalOutlet = item?.outletAmount;
  
          let totalTax = item?.taxDetails?.reduce((sum, item) => sum + item?.amount, 0);
  
          let totalExclTax = (totalAmount * totalTax) / 100;
  
  
         return { ...item, totalAmount: totalAmount, totalOutlet: totalOutlet,exclusiveTax:totalExclTax };
        }
      
      });
    
      // If you want to update the state, uncomment the line below
      // console.log(calculatedValues);
      const areEqual = JSON.stringify(calculatedValues) === JSON.stringify(checkedDataList);
      if (!areEqual) {
        setCheckedDataList(calculatedValues);
      }
      //  setCheckedDataList(calculatedValues);
    };
    const calculateFullTotal=(checkedDataList)=>{
      let totalAmount=0
      let totalPaid=0
      let totalInclusive=0
      let totalExclusive=0
      let totalOutlet =0
      checkedDataList?.forEach((item)=>{
            totalAmount +=item?.totalAmount
            totalPaid +=parseFloat(item?.payAmount)
            totalInclusive +=parseFloat(item?.inclusiveTax)
            totalExclusive +=parseFloat(item?.exclusiveTax)
            totalOutlet +=parseFloat(item?.totalOutlet)
      })
      console.log(totalExclusive);
      setAmountState({
              ...amountState,
               subTotal: parseFloat(totalAmount)+(totalExclusive ? parseFloat(totalExclusive):0),
               tax:(totalInclusive ? parseFloat(totalInclusive):0)+(totalExclusive ? parseFloat(totalExclusive):0),
              // grandTotal: totalAmount + totalTaxIncluded + totalTaxExcluded,
               totalOutlet: totalOutlet,
               totalPaid: totalPaid,
               balance: (parseFloat(totalAmount)+parseFloat(totalExclusive)) - totalPaid
             });
  
    }

    console.log(checkedDataList);

   function calculateEndDate(startDate, packageDuration, durationType) {
      const endDate = new Date(startDate);
      switch (durationType) {
          case 'Year':
              endDate.setFullYear(endDate.getFullYear() + packageDuration);
              break;
          case 'month':
              endDate.setMonth(endDate.getMonth() + packageDuration);
              break;
          case 'day':
              endDate.setDate(endDate.getDate() + packageDuration);
              break;
          // Add more cases for other duration types if needed
          default:
              console.error('Invalid duration type:', durationType);
              return null;
      }
      const formatDtae =`${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate.getDate().toString().padStart(2, '0')}`
      return formatDtae;
   }

 

useEffect(()=>{
    calculateTotalAmount(checkedDataList)
    calculateFullTotal(checkedDataList)
},[checkedDataList])


   useEffect(()=>{
      // let fullList=cartData?.packages?.map((obj)=>{
      //   return{
      //          ...obj,
      //          isChecked:false,
      //          enableMobilePackage:false,
      //          enableSmsPackage:false,
      //          smsPackage:null,
      //          smsPackageStartDate:"",
      //          smsPackageEndDate:"",
      //          enableBackupPrice:false,
      //          outletCount:1,
      //          outletAmount:obj?.locationPrice,
      //          packageFrmDate:today,
      //          packageToDate:calculateEndDate(today,obj?.packageDuration,obj?.durationType),
      //          enableWhatsappPackage:false,
      //          whatsappPackage:null,
      //          whatsappPackageStartDate:"",
      //          whatsappPackageEndDate:"",
      //          orderTotal:parseFloat(obj?.sellingPrice)+parseFloat(obj?.locationPrice),
      //          payAmount:0,
      //          totalAmount:0,
      //          inclusive:obj?.taxDetails !== undefined && obj?.taxDetails[0]?.includedPrice,
      //          inclusiveTax:0,
      //          exclusiveTax:0
      //          // orderTotal:0
      //       }
      //    })
      //  setCartFullList(fullList)

      if (cartData) {
         const countryExists = countryList.filter(item => item.code === country);
         let fullList = cartData.packages.filter(obj => obj.country === countryExists[0]?.name).map(obj => ({
             ...obj,
             isChecked: false,
             enableMobilePackage: false,
             enableSmsPackage: false,
             smsPackage: null,
             smsPackageStartDate: "",
             smsPackageEndDate: "",
             enableBackupPrice: false,
             outletCount:obj?.planType === "Enterprice" ? 1 : 0,
            //  outletCount:1,
            //  outletAmount: obj.locationPrice,
             outletAmount:obj?.planType === "Enterprice" ? obj.locationPrice : 0,
             packageFrmDate: today,
             packageToDate: calculateEndDate(today, obj.packageDuration, obj.durationType),
             enableWhatsappPackage: false,
             whatsappPackage: null,
             whatsappPackageStartDate: "",
             whatsappPackageEndDate: "",
             orderTotal: parseFloat(obj.sellingPrice) + parseFloat(obj.locationPrice),
             payAmount: 0,
             totalAmount: 0,
             inclusive: obj.taxDetails !== undefined && obj.taxDetails[0]?.includedPrice,
             inclusiveTax: 0,
             exclusiveTax: 0
           }));
     
         setCartFullList(fullList);
       }
   },[cartData])
   console.log(cartFullList);

   useEffect(()=>{
  
      if(myPlanSingleView===undefined){
         const checkedItems = cartFullList?.filter(item => item.isChecked);
         setCheckedDataList(checkedItems)
      }
   },[cartFullList])

  
   useEffect(()=>{
     if(currentCounry !== undefined){
      viewAllSmsPackageAPICall()
      viewAllWhatsappPackageAPICall()
      freeTrialDurationAPICall()
      if(singleViewPlan){
         viewPlansSingleAPICall({packageId:singleViewPlan})
      }
     }
     
   },[currentCounry,singleViewPlan])



   



 

   const packahedataToBackend=checkedDataList?.map((item)=>{
      return{
         // packageId:obj?._id,
         // module:obj?.module,
         // outletNo:obj?.outletCount,
         // outletAmount:obj?.outletAmount,
         // backupPrice:obj?.enableBackupPrice===true?obj?.backupPrice:0,
         // totalAmount:Number(obj?.sellingPrice)+(obj?.enableBackupPrice===true&&Number(obj?.backupPrice))+Number(obj?.obj?.outletAmount),
         // fromDate:obj?.packageFrmDate,
         // toDate:obj?.packageToDate
         packageId:item?._id,
        module:item?.module,
        outletNo:item?.outletCount,
        outletAmount:item?.outletAmount,
        backupPrice:item?.enableBackupPrice===true?item?.backupPrice:0,
        totalAmount:item?.totalAmount,
        fromDate:`${today} 12:00:00`,
        toDate:`${calculateEndDate(today,item?.packageDuration,item?.durationType)} 12:00:00`,
        paidAmount:Number(item?.payAmount),
        sellingPrice:item?.sellingPrice,
        smspackageid:item?.enableSmsPackage === true ? item?.smsPackage?._id : "",
        smspackageprice:item?.enableSmsPackage === true ? parseFloat(item?.smsPackage?.sellingtotal) : 0,
        smspackageqty:item?.enableSmsPackage === true ? parseFloat(item?.smsPackage?.sellingQty) : 0,
        whatsappid:item?.enableWhatsappPackage === true ? item?.whatsappPackage?._id : "",
        whatsappPrice:item?.enableWhatsappPackage === true ? parseFloat(item?.whatsappPackage?.sellingtotal) : 0,
        whatsappQty:item?.enableWhatsappPackage === true ? parseFloat(item?.whatsappPackage?.sellingQty) : 0,
        taxInclusive:item?.inclusiveTax,
        taxexclusive:item?.exclusiveTax

      }
   })
   const packagedataToBackendFreeTrial=checkedDataList?.map((item)=>{
      return{
         packageId:item?._id,
        module:item?.module,
        outletNo:item?.outletCount,
        outletAmount:item?.outletAmount,
        backupPrice:item?.enableBackupPrice===true?item?.backupPrice:0,
        totalAmount:item?.totalAmount,
        fromDate:`${today} 12:00:00`,
      //   toDate:`${calculateEndDate(today,item?.packageDuration,item?.durationType)} 12:00:00`,
        paidAmount:Number(item?.payAmount),
        sellingPrice:item?.sellingPrice,
        smspackageid:item?.enableSmsPackage === true ? item?.smsPackage?._id : "",
        smspackageprice:item?.enableSmsPackage === true ? parseFloat(item?.smsPackage?.sellingtotal) : 0,
        smspackageqty:item?.enableSmsPackage === true ? parseFloat(item?.smsPackage?.sellingQty) : 0,
        whatsappid:item?.enableWhatsappPackage === true ? item?.whatsappPackage?._id : "",
        whatsappPrice:item?.enableWhatsappPackage === true ? parseFloat(item?.whatsappPackage?.sellingtotal) : 0,
        whatsappQty:item?.enableWhatsappPackage === true ? parseFloat(item?.whatsappPackage?.sellingQty) : 0,
        taxInclusive:item?.inclusiveTax,
        taxexclusive:item?.exclusiveTax

      }
   })
   const bodyToPurchaseAPI={
      cusId:cartData?._id,
      package:packahedataToBackend
   }

   const bodyToFreeTrial={
      cusId:cartData?._id,
      package:packagedataToBackendFreeTrial !== undefined && packagedataToBackendFreeTrial[0]
   }

   const bodyToAddOutlet={
      cusId:cartData?._id,
      packageId:checkedDataList !== undefined && checkedDataList[0]?._id,
      // planId: "66027aefca98161572b6f3be",
      outletNo:checkedDataList !== undefined &&  checkedDataList[0]?.outletCount,
      outletAmount:checkedDataList !== undefined &&  checkedDataList[0]?.outletAmount,
      totalAmount:checkedDataList !== undefined &&  checkedDataList[0]?.totalAmount,
      fromDate: `${today} 12:00:00`,
      endDate:checkedDataList !== undefined && `${calculateEndDate(today,checkedDataList[0]?.packageDuration,checkedDataList[0]?.durationType)} 12:00:00`,
      isRenew: false,
      // pk: "66027bd72068992e3bf61f5a"
   }

   const freeTrialTrue=()=>{
      setIsFreeTrial(true)
   }

   

   const meid ="202402150001"
   const key ="lC7IcC3/wiqxs3wN0ksW0JLAxdnR8NhRm42k7LIofQk="
   const amount = amountState?.subTotal

  

   const txnDetails = {
      meId: meid,
      merchantOrderNo: Math.floor(Math.random() * 90000) + 10000,
      amount: amount,
      countryCode: "ARE",
      currencyCode: "AED",
      txnType: "SALE",
      channel: "WEB",
      userId: "sani@antino.io",
      planId: "",
      SuccessUrl: "/Response.jsp",
      FailUrl: "/Response.jsp"
    };
    const customerDetails = {
      name: "jsCybsOn",
      email: "successful.payment@tabby.ai",
      phone: "500000001",
      uniqueId: ""
    };
    const billingDetails = {
      bill_address: "123",
      bill_city: "Gurgaon",
      bill_state: "Hariyana",
      bill_country: "India",
      bill_zip: "110038"
    };
    const shipDetails = {
      ship_address: "",
      ship_city: "",
      ship_state: "",
      ship_country: "",
      ship_zip: "",
      ship_days: "",
      address_count: ""
    };
    
    const itemDetails = {
      item_count: "",
      item_value: "",
      item_category: ""
    };
    
    const otherDetails = {
      udf_1: "",
      udf_2: "",
      udf_3: "",
      udf_4: "",
      udf_5: "",
      udf_6: ""
    };
    const orderDetails = {
      txnDetails: txnDetails,
      customerDetails: customerDetails,
      billingDetails: billingDetails,
      shipDetails: shipDetails,
      itemDetails: itemDetails,
      otherDetails: otherDetails
    };

const compute = `${txnDetails.meId}|${txnDetails.merchantOrderNo}|${txnDetails.amount}|${txnDetails.countryCode}|${txnDetails.currencyCode}`;

const test = JSON.stringify(orderDetails);
// const test = orderDetails;

 const encryptedData = encrypt(test,key); 


const hash =  checksum(compute);


const paymode = '';

//  const spObj = new JsCheckout();
// const spObj = "";
const jsCheckoutOptions = {
   merchantId: txnDetails.meId,
   // internalKey:txnDetails?.key
 };
 
  
   // console.log(spObj);
   // const spObj = typeof JsCheckout !== 'undefined' ? new JsCheckout() :   alert("The payment gateway is currently loading. Please wait and try again.");
  

  
   const checkoutPageFn=()=>{
     
      // navigateTo("/profileinfomycart/checkout")
      if(checkedDataList?.length === 0){
         alert("Select Product")
      }
      else{
         if(country==="IN"){
            AddOrderApiCall(
               bodyToPurchaseAPI,
              //  updateListner,
              //  setOpenSuccessSnackbar,
              //  setOpenErrorSnackbar,
              //  setSnackMsg
               );
         }
         else{
         if (typeof JsCheckout !== 'undefined') {  
            let b = new JsCheckout();
            b.Init(jsCheckoutOptions);
            b.Pay(paymode, encryptedData, hash, JSON.stringify(orderDetails), CallbackForResponse);
        } else {
            alert("The payment gateway is currently loading. Please wait and try again.");
        }
         }
      
        
      }
    
   }

   const checkoutPageFnFreeTrial=()=>{
      localStorage.setItem("isFreetrial",true)
      if(checkedDataList?.length === 0){
         alert("Select Product")
      }
      else{
         console.log("else");
         if(country==="IN"){
            AddOrderApiCall(
               bodyToPurchaseAPI,
              //  updateListner,
              //  setOpenSuccessSnackbar,
              //  setOpenErrorSnackbar,
              //  setSnackMsg
               );
         }
         else{
         if (typeof JsCheckout !== 'undefined') {
             let a = new JsCheckout();
             a.Init(jsCheckoutOptions);
             a.Pay(paymode, encryptedData, hash, JSON.stringify(orderDetails), CallbackForResponse);

            
        } else {
            alert("The payment gateway is currently loading. Please wait and try again.");
        }
       
          }
      
        
      }
     

   }

   const CallbackForResponse = (response) => {
      console.log(response);       
      
  };

 



 

  

   const [decryptData,setDecryptData]=useState()
   
  // Listen for the custom event on the document
   document.addEventListener('transactionEvent', function (event) { 
   const payload = event.detail;  
   const decrytpedData = decrypt(payload,key); 
   const decryptobj=JSON.parse(decrytpedData)
   setDecryptData(decryptobj)
   // if(decryptobj?.status === "Successful"){
   //    addCustomerPurchaseAPICall(bodyToPurchaseAPI);
   //  }
   console.log("transactionEvent", payload); 
   console.log("decryptdata",decrytpedData);
   });



  
   
  const FreeTrial = localStorage.getItem("isFreetrial")
//   const singleViewId=localStorage.getItem("singleViewId")
console.log(decryptData);
   useEffect(()=>{
       if(decryptData?.status === "Successful"){
         if(FreeTrial){
            freeTrialAPICall(
               bodyToFreeTrial,
               setOpenSuccessSnackbar,
               setOpenErrorSnackbar,
               setSnackMsg
            )
            localStorage.removeItem("isFreetrial")
         }
         if(singleViewPlan){
            addOutletAPICall(
               bodyToAddOutlet,
               setOpenSuccessSnackbar,
               setOpenErrorSnackbar,
               setSnackMsg
            )
            
         }
         else{
            addCustomerPurchaseAPICall(
               bodyToPurchaseAPI,
               setOpenSuccessSnackbar,
               setOpenErrorSnackbar,
               setSnackMsg
            );
         }
       
      }
   },[decryptData])

   

  useEffect(() => {
   console.log(jsCheckoutOptions,"txndetails");
   const script = document.createElement("script");
   script.src = `https://uatcheckout.safexpay.ae/ms-transaction-core-1-0/jscheckout/js-checkoutNew.js`;
   script.async = true;
  
   script.onload = () => {
     /* global JsCheckout */
      let spObj = new JsCheckout();
      spObj.Init(jsCheckoutOptions);
      console.log(spObj);
      // setCheckoutPage(spObj?.Pay)
   

   };
   document.body.appendChild(script);
   return () => {
     document.body.removeChild(script);
   };
 }, [txnDetails]);




 useEffect(() => {
   token !== null && viewCartAPICall({ cusId: jwtDecode(token)?._id });
 }, [token, isModify]);


 useEffect(() => {
   if (myPlanSingleView !== undefined) {
      setCheckedDataList([{
         packageName: myPlanSingleView?.packageName,
         planType: myPlanSingleView?.planType,
         durationType: myPlanSingleView?.durationType,
         outletCount: 1,
         locationPrice: myPlanSingleView?.locationPrice,
         outletAmount: myPlanSingleView?.locationPrice,
         _id:myPlanSingleView?._id,
         packageDuration:myPlanSingleView?.packageDuration
      }])

      
   
   }
 }, [myPlanSingleView]);

 console.log(freeTrialDuration);

 
 

  
   
  return (
    <div className='cart-main-container'>
      {/* old */}
      <Helmet>
        <link rel="stylesheet" href="https://fonts.gstatic.com" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap" />
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.7.0/css/all.css" />
        <link rel="stylesheet" href="https://uatcheckout.safexpay.ae/ms-transaction-core-1-0/jscheckout/International-checkout-b/resources/css/bootstrap.min.css" />
        <link rel="stylesheet" href="https://uatcheckout.safexpay.ae/ms-transaction-core-1-0/jscheckout/International-checkout-b/resources/css/International-Checkout-page.css" />
        <link rel="stylesheet" href="https://uatcheckout.safexpay.ae/ms-transaction-core-1-0/jscheckout/International-checkout-b/resources/css/Roboto-font-family.css" />
        <link rel="stylesheet" href="https://uatcheckout.safexpay.ae/ms-transaction-core-1-0/jscheckout/International-checkout-b/resources/css/intlTelInput.css" />
        <link rel="stylesheet" href="https://uatcheckout.safexpay.ae/ms-transaction-core-1-0/jscheckout/resources/css/checkout.css" />

        <script src="https://uatcheckout.safexpay.ae/ms-transaction-core-1-0/jscheckout/js-checkoutNew.js" async defer crossOrigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/crypto-js/4.0.0/crypto-js.min.js" async defer crossOrigin="anonymous"></script>
        <script src="https://uatcheckout.safexpay.ae/ms-transaction-core-1-0/jscheckout/resources/js/jquery.min.js" async defer crossOrigin="anonymous"></script>
        <script src="https://uatcheckout.safexpay.ae/ms-transaction-core-1-0/jscheckout/resources/js/bootstrap.min.js" async defer crossOrigin="anonymous"></script>
        <script src="https://uatcheckout.safexpay.ae/ms-transaction-core-1-0/jscheckout/International-checkout-b/resources/js/popper.min.js" async defer crossOrigin="anonymous"></script>
        <script src="https://uatcheckout.safexpay.ae/ms-transaction-core-1-0/jscheckout/International-checkout-b/resources/js/intlTelInput.js" async defer crossOrigin="anonymous"></script>
        <script src="https://uatcheckout.safexpay.ae/ms-transaction-core-1-0/js/scripts/gPayScript.js" async defer crossOrigin="anonymous"></script>
      </Helmet> 
      <div className='cart-container'>
         <div className="cart-left-container">
            {myPlanSingleView === undefined ?
           <div className='cart-left-item-container'>
            {
               cartFullList?.map((r,i)=>(
                  <>
                 <div  className='cart-left-item-single-container' style={{flexDirection:"column"}}>
                  <div style={{display:"flex",width:"100%",justifyContent:"space-between",alignItems:"center"}}>
                 <div className='cart-left-item-single-checkbox-container'>
                    <input 
                      type="checkbox"
                      checked={r?.isChecked}
                      onChange={()=>checkboxCheckFn(i,"isChecked")}
                      
                      />
                 </div>
                 <div className='cart-left-item-single-image-label-container'>
                     <div className='cart-left-item-single-image' style={{backgroundColor:r?.hexCode}}>
                        <img src={`data:name;base64,${r?.logo?.data}`} alt="" />
                     </div>
                     <div style={{marginLeft:"8%",display:"flex",flexDirection:"column",justifyContent:"center"}}>
                         <h4 style={{fontSize:'1rem',fontWeight:600,margin:0}}>{r?.packageName}</h4>
                         <p style={{fontSize:'0.7rem',fontWeight:600,margin:0}}>{r?.planType}</p>
                         <p style={{fontSize:'0.7rem',fontWeight:600,margin:0}}>Price - {r?.sellingPrice}</p>
                     </div>
                 </div>
                 <div style={{display:"flex",flexDirection:"column"}}>
                    
                 </div>
                 <div style={{cursor:"pointer"}} onClick={()=>removeItemFromCart(r?._id)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" class="bi bi-trash3" viewBox="0 0 16 16">
                       <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
                    </svg>
                 </div>
                 </div>
                 <div className='cart-left-item-bottom-container'>
                    <div className='cart-left-item-bottom-container-single'>
                       <div style={{display:"flex",alignItems:"center",margin:"4px 0"}}>
                          <input 
                             type="checkbox" 
                             style={{accentColor:"blue",cursor:"pointer",width:"16px",height:"16px"}}
                             checked={r?.enableBackupPrice}
                             onChange={()=>checkboxCheckFn(i,"enableBackupPrice")}
                             />
                          <label htmlFor="" style={{cursor:"pointer",fontSize:"0.8rem",margin:"0 0 0 8px",fontWeight:600}}>Backup Price</label>
                           <i class="bi bi-info-circle" style={{color:"red",marginLeft:"8px"}}></i>
                           <p style={{margin:0}}>- {r?.backupPrice}</p>
                       </div>
                        
                    </div>
                 </div>
                 </div>
                
                 </>
               ))
            }
              
              
           </div>
         :
            <div className='cart-left-item-container'></div>
            } 
        
         </div>
         <div className="cart-right-container">
           <div className='cart-right-top-container'>
            {
              checkedDataList?.length !== 0 && checkedDataList !== undefined ? checkedDataList?.map((r,i)=>(
                  <div className='cart-right-top-single-container'>
                  <div className='cart-right-top-single-one-container'>
                      <div className='cart-left-item-single-image' style={{width:"40px",height:"40px",backgroundColor:r?.hexCode}}>
                         <img src={`data:name;base64,${r?.logo?.data}`} alt="" />
                      </div>
                    <div style={{marginLeft:"6%"}}>
                     <p style={{fontSize:"1.1rem",fontWeight:600}}>{r?.packageName}</p>
                     <p style={{backgroundColor:"rgb(223 238 223)",fontSize:"0.7rem",fontWeight:600,padding:"1%"}}>{r?.planType} <span style={{fontSize:"0.7rem",fontWeight:600,color:"green"}}>/{r?.durationType}</span></p>
                    </div>
                    <p style={{margin:"2% 2% 0 auto",fontSize:"0.75rem",fontWeight:700}}>{r?.sellingPrice}</p>
                  </div>
                  {
                     r?.planType !== "Enterprice" &&
                     <div className='cart-right-top-single-one-container'>
                       <p style={{margin:"0 0 0 15%",fontSize:"0.8rem",fontWeight:600,color:"#0ae30a"}}>1 Outlet Included</p>
                     </div> 
                  }
                 
                  <div className='cart-right-top-single-one-container'>
                     <p style={{margin:"2% 2% 0 15%",fontSize:"0.75rem",fontWeight:600}}>Outlet Amount</p>
                     <div style={{display:"flex",width:"25%",justifyContent:"space-evenly",alignItems:"center"}}>
                        <button disabled={r?.outletCount===0}  onClick={()=>decButtonFn(i)} className='cart-plus-btn'>-</button>
                        <p style={{color:"blue",fontSize:"0.8rem",margin:"0"}}>{r?.outletCount}</p>
                        <button onClick={()=>incButtonFn(i)} className='cart-plus-btn'>+</button>
                     </div>
                     <p style={{margin:"2% 2% 0 auto",fontSize:"0.75rem",fontWeight:700}}>{r?.outletAmount?.toFixed(2)}</p>
                  </div>
                  {
                     r?.enableSmsPackage &&
                     <div className='cart-right-top-single-one-container' style={{justifyContent:"space-between",}}>
                     <p style={{fontSize:"0.8rem",fontWeight:600,margin:"0 0 0 15%"}}>SMS Package</p>
                     <p style={{fontSize:"0.8rem",fontWeight:600,margin:"0 2% 0 0"}}>{r?.smsPackage?.sellingtotal}</p>
                  </div>
                  }
                   {
                   r?.enableWhatsappPackage &&
                   <div className='cart-right-top-single-one-container' style={{justifyContent:"space-between",}}>
                   <p style={{fontSize:"0.8rem",fontWeight:600,margin:"0 0 0 15%"}}>Whatsapp Package</p>
                   <p style={{fontSize:"0.8rem",fontWeight:600,margin:"0 2% 0 0"}}>{r?.whatsappPackage?.sellingtotal}</p>
                </div>
                }
                 {
                  r?.enableBackupPrice &&
                  <div className='cart-right-top-single-one-container' style={{justifyContent:"space-between",}}>
                  <p style={{fontSize:"0.8rem",fontWeight:600,margin:"0 0 0 15%"}}>Backup price</p>
                  <p style={{fontSize:"0.8rem",fontWeight:600,margin:"0 2% 0 0"}}>{r?.backupPrice}</p>
               </div>
                 }
                 
                  {/* <p style={{textAlign:"right",fontSize:"0.8rem",fontWeight:600}}>{r?.orderTotal}</p> */}
                  </div>
               ))
               :
               <div className="empty-cart-container" style={{margin:"20% 2%"}}>
                  <img src={emptyCart} alt="" style={{width:"34%"}}/>
               </div>
            
            }
           
           </div>
           <div className='cart-right-bottom-container'>
           
             
          
   
               <div className='cart-right-bottom-single-container'>
                 <p>Sub Total</p>
                 <p style={{fontSize:"1.1rem",color:"red",fontWeight:"600"}}>{amountState?.subTotal}</p>
               </div>
               <div className='cart-right-bottom-single-container'>
                 <p>Tax</p>
                 <p>{amountState?.tax?.toFixed(2)}</p>
               </div>
               <div style={{display:"flex",justifyContent:"space-between"}}>
                  <button 
                    onClick={checkoutPageFn}
                   className='buy-now-button-cart' style={{width:"63%",cursor:"pointer"}}>Buy Now</button> 

                   {/* <PaymentCheckoutProcessPage subTotal={amountState?.subTotal} /> */}
                   {
                     myPlanSingleView === undefined &&
                     <button 
                     // onMouseUp={freeTrialTrue}
                     onClick={()=>{
                        checkoutPageFnFreeTrial()
                        
                       
                     }}
                     disabled={checkedDataList?.length>1}
                     className={checkedDataList?.length>1?"free-trial-cart disable-btn":'free-trial-cart'} style={{width:"35%"}}>
                        {`${freeTrialDuration !== undefined ? freeTrialDuration?.duration :""} ${freeTrialDuration !== undefined ? freeTrialDuration?.durationType :""}`} Free Trial</button>
                   }
                
               </div>
               <hr  style={{margin:"8% 0 4% 0",borderBottom:"1px solid #fff"}}/>
               <div style={{display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center"}}>
               <p className='footer-text-cart'> Powerful Features Tailored to Your Business Needs, Through
              advanced automation, real-time data analytics, and customizable
              modules, EYE-T ERP offers unparalleled insights into your business
              operations. Gain complete visibility into inventory management,
              supply chain logistics, financial transactions, HR functions, and
              more, all within a unified platform.</p>
              <hr  style={{margin:"4% 0 4% 0",borderBottom:"1px solid #fff"}}/>

               </div>
              
           </div>
         </div>
      </div>

      <SuccessSnackBar
        open={openSuccessSnackbar} 
        handleClose={closeSuccessSnackbar} 
        message={snackMsg}
      />
      <ErrorSnackBar
         open={openErrorSnackbar} 
         handleClose={closeErrorSnackbar} 
         message={snackMsg}
      />

    
    </div>
  )
}
