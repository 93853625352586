import React, { useEffect, useState } from 'react'
import '../../../Css/Profile/profileinfomyplans.css'
import totalPackage from "../../../Assets/Icons/New/Total Package.svg"
import totalModule from "../../../Assets/Icons/New/Active Modules.svg"
import totalOutlet from "../../../Assets/Icons/New/Active Outlets.svg"
import profileman from "../../../Assets/Images/New/Profile.png"
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { balancePaymentAPICall, myPlansAPICall } from '../../../API/Profile/myPlansAPICall'
import { refundAPICall } from '../../../API/Payment/PaymentAPI'
import cmpnyLogo from "../../../Assets/Images/New/Oopacks .0.png"
import { Dialog, IconButton, MenuItem, Select, Tooltip } from '@mui/material'
import upgrade from "../../../Assets/Images/New/money-back.png"
import VisibilityIcon from '@mui/icons-material/Visibility';
import { checksum, decrypt, encrypt } from './aes'
import { Helmet } from 'react-helmet'
import SuccessSnackBar from '../../Custom Components/SuccessSnackBar'
import ErrorSnackBar from '../../Custom Components/ErrorSnackBar'

export const ProfileInfomyPlans = () => {
  let navigate = useNavigate();
    const myPlansData=useSelector((state)=>state.myPlansSlice.value)
    const country=localStorage.getItem("country")
    const data=[
      {no:121,name:"aswathi",type:"textile",amt:200,validity:"1 year",pDate:"12-12-24",expDate:"12-12-24",noOfOutlet:2,remaining:2,status:"Active"}
    ]
    const currentCounry=useSelector((state)=>state.locationList.value)
    const [selectedOptionType, setSelectedOptionType] = useState();
    const [borderColor, setBorderColor] = useState(getBorderColor(myPlansData?.packageList !== undefined && myPlansData?.packageList[0]?.packageName));
    const [isExpand,setIsExpand]=useState(null)
    const [filteredData,setFilteredData]=useState([])
    
    const[showResults,setShowResults]=useState(false)
    const [cancelPopup,setCancelPopup]=useState(false)
    const [refundConfirm,setRefundConfirm]=useState(false)
    const refundFormInitialState={
        customerName:'',
        amount:'',
        reason:''
    }
    const [refundForm,setReFundForm]=useState(refundFormInitialState)
    const [refundData,setRefundData]=useState()
    const [isModify,setIsModify]=useState(false)
    const [showPackageExpiry,setShowPackageExpiry]=useState(false)
    const [showOutletExpiry,setShowOutletExpiry]=useState(false)
    const [activePlanExpirySingle,setActivePlanExpirySingle]=useState()
    const [viewModules,setViewModules]=useState(0)
    const [expiredPlanSingle,setExpiredPlanSingle]=useState([])
    const [renewDialog,setRenewDialog]=useState(false)
    const [selectedOption, setSelectedOption] = useState('month');
    const [balance, setBalance] = useState();
    const [balanceId,setBalanceId]=useState()
    const [snackBarStates, setSnackBarStates] = useState({
      success: false,
      error: false,
      message: "",
      alert: false,
    });
    const closeSnackbar = () => {
      setSnackBarStates({
        ...snackBarStates,
        success: false,
        error: false,
        alert: false,
        message: "",
      });
    };
    const handleOptionClick = (option) => {
        setSelectedOption(option === selectedOption ? selectedOption : option);
      };
    const updateListner=()=>{
        setIsModify(!isModify)
    }

 
   
    function viewClickCancel() {
        setShowResults(false)
    
    }


 useEffect(()=>{
     if(myPlansData !== undefined){
        setSelectedOptionType(myPlansData?.packageList[0]?.packageName)
     }
 },[myPlansData])

 useEffect(()=>{
    let data= myPlansData?.packageList?.filter((obj)=>{
         if(obj?.packageName===selectedOptionType){
            return obj
         }
    })
    setFilteredData(data)
 },[selectedOptionType])

    useEffect(()=>{
      if(currentCounry!==undefined){
        myPlansAPICall()
      }
    },[currentCounry])

    console.log(filteredData);

   

    //  body to refund body
    const bodyToRefund={
        _id:refundData?._id,
        paymentId:refundData?.paymentId,
        reason:refundForm?.reason,
        amount:refundForm?.amount,
        packageId:refundData?.packageId
    }

    const refundButton=()=>{
        if(refundForm?.reason===""){
            alert('enter reason')
        }
        else{
            refundAPICall(bodyToRefund,updateListner,setCancelPopup,setRefundConfirm)
            // setCancelPopup(false)
            // setRefundConfirm(true)
        }
       

    }

    // renew button function
    const renewButtonFn=(id)=>{
        localStorage.setItem("planId",id)
        navigate("/profileinfomycart");
    }
    const clickPackageExpiry=()=>{
        setShowPackageExpiry(!showPackageExpiry)
    }
    const clickOutletExpiry=()=>{
        setShowOutletExpiry(!showOutletExpiry)
     }

    //active plan single view function
    const viewClick=()=>()=>{
      //  let filterData = myPlansData?.activePackageList?.filter((obj)=>{
      //      if(obj?._id===id){
      //       return obj
      //      }
      //   })
      //  setActivePlanExpirySingle(filterData[0])
        setShowResults(true)
        
    }

    // expired plan
  const expiredViewClick=(id)=>()=>{
    let data = myPlansData?.expiredPackageList?.filter((obj)=>{
        if(obj?._id===id){
            return obj
           }
    })
    setActivePlanExpirySingle(data[0])
    setShowResults(true)
  }


//   new 
useEffect(() => {
    setBorderColor(getBorderColor(selectedOptionType));
  }, [selectedOptionType]);

  const handleOptionTypeClick = (option) => {
    setSelectedOptionType(option?.packageName);
};

console.log(myPlansData);
function getBorderColor(type) {
  switch (type) {
    case 'ERP-Textile':
      return '#e15871';
    case 'ERP-Supermarket':
      return 'blue';
    default:
      return 'white'; // Default color if type is not recognized
  }
}

const clickDownArrow=(index)=>{
    setIsExpand(index);
  }
  const clickUpArrow=()=>{
    setIsExpand(null);
  }

  const clickAddOutlet=()=>{
     localStorage.setItem("singleViewId",filteredData[0]?._id)
     navigate("/profileinfomycart");
  }

  const purchseBtnClick=(r)=>()=>{
    let data = {
      packageName:r?.packageName,
      durationType:r?.durationType,
      planType:r?.packageType
    }
    localStorage.setItem("data",JSON.stringify(data))
    navigate("/productreview");
  }
  

 
  

  const clickBalanceButton=(data)=>{
     setBalance(data?.balance);
     setBalanceId(data?._id)
  }
  const clickBalanceButton2=()=>{
    setTimeout(()=>{
      checkoutPageFn();
    },1000)
  
  }
  // useEffect(() => {
  //   const handleStorageChange = () => {
  //     setBalance(localStorage.getItem("balanceAmt"));
  //   };
    
  //   window.addEventListener("storage", handleStorageChange);
    
  //   return () => {
  //     window.removeEventListener("storage", handleStorageChange);
  //   };
  // }, []);

  // Now you can access balance anywhere within the component
  console.log("Current balance:", balance);
  


  const meid = "202402150001"
  const key = "lC7IcC3/wiqxs3wN0ksW0JLAxdnR8NhRm42k7LIofQk="
  const amount = Number(balance)

 

  const txnDetails = {
    //  key:key,
     meId: meid,
     merchantOrderNo: Math.floor(Math.random() * 90000) + 10000,
     amount: amount,
     countryCode: "ARE",
     currencyCode: "AED",
     txnType: "SALE",
     channel: "WEB",
     userId: "sani@antino.io",
     planId: "",
     SuccessUrl: "/Response.jsp",
     FailUrl: "/Response.jsp"
   };
   const customerDetails = {
     name: "jsCybsOn",
     email: "successful.payment@tabby.ai",
     phone: "500000001",
     uniqueId: ""
   };
   const billingDetails = {
     bill_address: "123",
     bill_city: "Gurgaon",
     bill_state: "Hariyana",
     bill_country: "India",
     bill_zip: "110038"
   };
   const shipDetails = {
     ship_address: "",
     ship_city: "",
     ship_state: "",
     ship_country: "",
     ship_zip: "",
     ship_days: "",
     address_count: ""
   };
   
   const itemDetails = {
     item_count: "",
     item_value: "",
     item_category: ""
   };
   
   const otherDetails = {
     udf_1: "",
     udf_2: "",
     udf_3: "",
     udf_4: "",
     udf_5: "",
     udf_6: ""
   };
   const orderDetails = {
     txnDetails: txnDetails,
     customerDetails: customerDetails,
     billingDetails: billingDetails,
     shipDetails: shipDetails,
     itemDetails: itemDetails,
     otherDetails: otherDetails
   };

const compute = `${txnDetails.meId}|${txnDetails.merchantOrderNo}|${txnDetails.amount}|${txnDetails.countryCode}|${txnDetails.currencyCode}`;

const test = JSON.stringify(orderDetails);
// const test = orderDetails;

const encryptedData = encrypt(test,key); 


const hash =  checksum(compute);


const paymode = '';

const jsCheckoutOptions = {
  merchantId: txnDetails.meId,
  // internalKey:txnDetails?.key
};

const checkoutPageFn=()=>{
     
 
 
     if(country==="IN"){
      
     }
     else{
     if (typeof JsCheckout !== 'undefined') {  
        console.log(orderDetails);
        let b = new JsCheckout();
        b.Init(jsCheckoutOptions);
        b.Pay(paymode, encryptedData, hash, JSON.stringify(orderDetails), CallbackForResponse);
    } else {
        alert("The payment gateway is currently loading. Please wait and try again.");
    }
     }
  
    
  

}

const CallbackForResponse = (response) => {
  console.log(response);       
  
};

const [decryptData,setDecryptData]=useState()
   
  // Listen for the custom event on the document
   document.addEventListener('transactionEvent', function (event) { 
   const payload = event.detail;  
   const decrytpedData = decrypt(payload,key); 
   const decryptobj=JSON.parse(decrytpedData)
   setDecryptData(decryptobj)
   console.log("transactionEvent", payload); 
   console.log("decryptdata",decrytpedData);
   });


 
   useEffect(()=>{
    if(decryptData?.status === "Successful"){
      balancePaymentAPICall({
        paidAmount:Number(balance),
        _id:balanceId
      },setSnackBarStates,snackBarStates)
     
    console.log('apicall');
    
   }
},[decryptData])

useEffect(() => {
  const script = document.createElement("script");
  script.src = `https://uatcheckout.safexpay.ae/ms-transaction-core-1-0/jscheckout/js-checkoutNew.js`;
  script.async = true;
 
  script.onload = () => {
    /* global JsCheckout */
     let spObj = new JsCheckout();
     spObj.Init(jsCheckoutOptions);
     console.log(spObj);
     // setCheckoutPage(spObj?.Pay)
  

  };
  document.body.appendChild(script);
  return () => {
    document.body.removeChild(script);
  };
 
}, [txnDetails]);


 
 
 





  console.log(filteredData);
  return (
    <div className='my-plans-main-container'>
        <Helmet>
        <link rel="stylesheet" href="https://fonts.gstatic.com" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap" />
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.7.0/css/all.css" />
        <link rel="stylesheet" href="https://uatcheckout.safexpay.ae/ms-transaction-core-1-0/jscheckout/International-checkout-b/resources/css/bootstrap.min.css" />
        <link rel="stylesheet" href="https://uatcheckout.safexpay.ae/ms-transaction-core-1-0/jscheckout/International-checkout-b/resources/css/International-Checkout-page.css" />
        <link rel="stylesheet" href="https://uatcheckout.safexpay.ae/ms-transaction-core-1-0/jscheckout/International-checkout-b/resources/css/Roboto-font-family.css" />
        <link rel="stylesheet" href="https://uatcheckout.safexpay.ae/ms-transaction-core-1-0/jscheckout/International-checkout-b/resources/css/intlTelInput.css" />
        <link rel="stylesheet" href="https://uatcheckout.safexpay.ae/ms-transaction-core-1-0/jscheckout/resources/css/checkout.css" />

        <script src="https://uatcheckout.safexpay.ae/ms-transaction-core-1-0/jscheckout/js-checkoutNew.js" async defer crossOrigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/crypto-js/4.0.0/crypto-js.min.js" async defer crossOrigin="anonymous"></script>
        <script src="https://uatcheckout.safexpay.ae/ms-transaction-core-1-0/jscheckout/resources/js/jquery.min.js" async defer crossOrigin="anonymous"></script>
        <script src="https://uatcheckout.safexpay.ae/ms-transaction-core-1-0/jscheckout/resources/js/bootstrap.min.js" async defer crossOrigin="anonymous"></script>
        <script src="https://uatcheckout.safexpay.ae/ms-transaction-core-1-0/jscheckout/International-checkout-b/resources/js/popper.min.js" async defer crossOrigin="anonymous"></script>
        <script src="https://uatcheckout.safexpay.ae/ms-transaction-core-1-0/jscheckout/International-checkout-b/resources/js/intlTelInput.js" async defer crossOrigin="anonymous"></script>
        <script src="https://uatcheckout.safexpay.ae/ms-transaction-core-1-0/js/scripts/gPayScript.js" async defer crossOrigin="anonymous"></script>
      </Helmet> 

    <div className='my-plans-container'>
         <div className='my-plans-container-one'>
          <div className='my-plans-container-one-single-card' style={{width:"30%",position:"relative"}}>
             <div style={{position:'absolute',bottom:0}}>
             <img src={profileman} alt="" style={{width:"50%"}}/>
             </div>
             <div style={{position:'absolute',right:"4%"}}>
               <h3 style={{margin:"3% 0",fontSize:"0.9rem"}}>Hello {myPlansData?.companyName}</h3>
               <p style={{margin:"3% 0",fontSize:"0.8rem",fontWeight:600,color:"rgb(188 186 186)"}}>It's Good To See You Again</p>
               <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
               <img src={cmpnyLogo} alt="" />
               </div>
              
             </div>
          </div>
          <div className='my-plans-container-one-single-card'>
              <img src={totalPackage} alt="" />
              <div>
                <p className='my-plans-number-label' style={{color:"#1817e6"}}>{myPlansData?.totalPackageCount}</p>
                <p className='my-plans-text-label'  style={{color:"#1817e6"}}>Total Package</p>
              </div>
          </div>
          <div className='my-plans-container-one-single-card'>
             <img src={totalModule} alt="" />
              <div>
                <p className='my-plans-number-label'  style={{color:"#e508c7"}}>{myPlansData?.totalModuleCount}</p>
                <p className='my-plans-text-label'  style={{color:"#e508c7"}}>Active Module</p>
              </div>
          </div>
          <div className='my-plans-container-one-single-card'>
             <img src={totalOutlet} alt="" />
              <div>
                <p className='my-plans-number-label' style={{color:"#e51448"}}>{myPlansData?.totalActiveOutLet}</p>
                <p className='my-plans-text-label' style={{color:"#e51448"}}>Active Outlets</p>
              </div>
          </div>
         </div>
         <div className='profile-myplans-second-container'>
          <div className='profile-myplans-second-single-container search-container-myplan'>
            <input type="text" placeholder='Search...'/>
            <div className='search-icon-my-plan'>
            <i class="bi bi-search "></i>
            </div>
           
           </div>
          <div onClick={clickPackageExpiry} className='profile-myplans-second-single-container package-expiry'>
            <p>{myPlansData?.totalPackageExpiryCount}&nbsp;&nbsp;Package Expiry</p>
            {
                showPackageExpiry ?
                <i class="bi bi-chevron-up"></i>
                :
                <i class="bi bi-chevron-down"></i>
            }
           
          </div>
          <div 
           onClick={clickOutletExpiry}
           className='profile-myplans-second-single-container package-expiry'>
            <p>{myPlansData?.totalOutletExpiryCount}&nbsp;&nbsp;Outlet Expiry</p>
            {
                showOutletExpiry ?
                <i class="bi bi-chevron-up"></i>
                :
               <i class="bi bi-chevron-down"></i>
            }
            
          </div>

           {/* dropdown container-package expirt */}
          {
            showPackageExpiry && 
            <div className='package-expiry-drop-down'>
                {
                   myPlansData?.packageNotificationList !== 0 && myPlansData?.packageNotificationList !== undefined ? myPlansData?.packageNotificationList?.map((r,i)=>(
                    <p>{r}</p>
                   )) 
                   :
                    <p>No Data</p>
                }
          </div>
          }
          

             {/* dropdown container-outlet expirt */}
       {
        showOutletExpiry&&
            <div className='outlet-expiry-drop-down'>
           {
                   myPlansData?.branchNotificationList !== 0 ? myPlansData?.branchNotificationList?.map((r,i)=>(
                    <p>{r}</p>
                   )) 
                   :
                    <p>No Data</p>
                }
          </div>
       }
             
         
        
         
         </div>

         {/* new */}
         <div className="single-user-bottom-container">
          <div className="single-user-first-container">
             
               {myPlansData?.packageList?.map((obj)=>(
                  <div 
                    className={"single-user-top-single-card"} 
                    onClick={() => handleOptionTypeClick(obj)}
                    style={{ borderColor: selectedOptionType === obj.packageName ? obj?.hexCode : 'white' }}
                    >
                        <div className="single-user-top-single-card-image" style={{backgroundColor:obj?.hexCode}}>
                           <img src={`data:name;base64,${obj?.logo?.data}`} alt="" />
                        </div>
                        <p>{obj?.packageName}</p>
                  </div>
              ))}
          </div>
          <div className="single-user-second-container">
            <p className="package-label">PACKAGE</p>
            <div className="new-global-table-container" style={{backgroundColor:"#eef1f9"}}>
              <table>
                 <thead>
                  <tr>
                     <th>Package No</th>
                     <th>ERP Type</th>
                     <th>Package Type</th>
                     <th>Amount</th>
                     <th>Validity</th>
                     <th>Purchase Date</th>
                     <th>Expiry Date</th>
                     <th>No Of Outlet</th>
                     <th>Remaining Outlet</th>
                     <th>Status</th>
                     <th></th>
                     <th></th>
                  </tr>
                 </thead>
                 <tbody>
                  {
                    filteredData?.map((r,i)=>(
                      <>
                      <tr>
                      <td>{r?.packageId}</td>
                      <td>{r?.packageName}</td>
                      <td>{r?.packageType}</td>
                      <td>{r?.amount}</td>
                      <td>{r?.validity}</td>
                      <td>{r?.purchaseDate}</td>
                      <td>{r?.expiryDate}</td>
                      <td>{r?.noOfOutlets}</td>
                      <td>{r?.remainingOutlets}</td>
                      <td>
                        <p className={
                          r?.status==="active"?
                          "status-active"
                         :r?.status==="expired"?
                           "status-expired"
                          :undefined
                        }>{r?.status?.toUpperCase()}</p>
                      </td>
                      <td>
                        {
                          isExpand === null ?
                           <IconButton onClick={()=>clickDownArrow(i)}>
                              <i class="bi bi-arrow-down"></i>
                            </IconButton>
                          :
                             <IconButton onClick={()=>clickUpArrow()}>
                                <i class="bi bi-arrow-up"></i>
                              </IconButton>
                        }
                       
                      
                      </td>
                      <td style={{background:"none",borderBottom:"none",display:"flex",justifyContent:"center"}}>
                        {
                            r?.status==="active" && r?.packageType==="Basic" ?
                            <div onClick={purchseBtnClick(r)} className='go-to-website' style={{backgroundColor:"#01818f"}}>
                            <img src={upgrade} alt="" style={{width:"18px"}}/>
                               <p>Upgrade</p> 
                            </div>
                            :r?.status==="expired"?
                            <div className='go-to-website' style={{backgroundColor:"blue"}}>
                            <i class="bi bi-arrow-repeat"></i>
                            <p>Renew</p> 
                            </div>
                            :undefined

                        }
                       
                       </td>
                       
                    </tr>
                    {
                       isExpand === i && (
                        <tr> 
                        <td colSpan={12}>
                         <div  className="new-global-table-container" style={{backgroundColor:"#eef1f9",display:"flex",justifyContent:"center"}}>
                         <table style={{width:"90%"}}>
                           <thead>
                            <tr>
                               <th>TXN</th>
                               <th>TXN Date</th>
                               {/* <th>Customer</th> */}
                               <th>TXN Expiry Date</th>
                               {/* <th>TXN Validity</th> */}
                               <th>Status</th>
                               <th>Amount</th>
                               <th>Balance</th>
                            </tr>
                           </thead>
                           <tbody>
                            {
                                r?.transactions?.map((k,i)=>(
                                    <tr>
                                    <td>{k?.purchaseId}</td>
                                    <td>{k?.txnDate}</td>
                                    {/* <td>--</td> */}
                                    <td>{k?.txnExpDate}</td>
                                    {/* <td>--</td> */}
                                    <td><p style={{fontSize:"0.8rem",fontWeight:600,color:"#0ac30a",margin:0}}>{r?.status?.toUpperCase()} </p></td>
                                    <td>{k?.amount}</td>
                                    <td>
                                      {
                                        k?.balance>0?
                                        <p  
                                        onMouseDown={()=>clickBalanceButton(k)}
                                        onClick={()=>clickBalanceButton2()}
                                        title="Pay" style={{backgroundColor:"blue",padding:"2px",borderRadius:"4px",color:"#fff",margin:0}}>{k?.balance}</p>
                                        :
                                        <p>0</p>
                                      }
                                    
                                    </td>
                                  </tr>
                                ))
                            }
                          
                           </tbody>
                         </table>
                         </div>
                         </td>
                      </tr>
                       )
                    }
                  
                    </>
                    ))
                  }
                
                 </tbody>
              </table>
            </div>
          </div>
          <div className="single-user-second-container">
            <div>

            </div>
            {/* <div style={{display:"flex",justifyContent:"space-between",margin:"1% 0"}}>
               <p className="package-label">Outlets</p>
               <button  onClick={clickAddOutlet} style={{border:"1px solid blue",borderRadius:"4px",backgroundColor:"transparent",padding:"8px",cursor:"pointer",color:"blue"}}>Add Outlet</button>
           </div> */}
           <div className='package-label'>
               <p  >Outlets</p>
               <p onClick={clickAddOutlet} style={{fontSize:"0.8rem",color:"blue",fontWeight:500, margin:"0 0 0 8px"}}>Add Outlet</p>
           </div>
            
              <div  className="new-global-table-container" style={{backgroundColor:"#eef1f9"}}>
                <table>
                   <thead>
                      <tr>
                        <th>Outlet Name</th>
                        <th>country</th>
                        <th>Package</th>
                        <th>Type</th>
                        <th>Outlet Purchase Date</th>
                        <th>Outlet Expiry Date</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                   </thead>
                   <tbody>
                    {
                      filteredData !== undefined &&  filteredData[0]?.outletList?.map((r,i)=>(
                            <tr>
                            <td>{r?.outletName}</td>
                            <td>{r?.country}</td>
                            <td>{r?.packageName}</td>
                            <td>{r?.packageType}</td>
                            <td>{r?.purchaseDate}</td>
                            <td>{r?.expiryDate}</td>
                            <td >
                               <p className="status-active" >{r?.status}</p>
                            </td>
                            {
                                r?.status==="expired"&&
                                <td style={{background:"none",borderBottom:"none",display:"flex",justifyContent:"center"}}>
                                <div onClick={()=>setRenewDialog(true)} className='go-to-website' style={{backgroundColor:"blue"}}>
                                  <i class="bi bi-arrow-repeat"></i>
                                  <p>Renew</p> 
                                </div>
                              </td>
                            }
                         </tr>
                        ))
                    }
                    
                   </tbody>
                </table>
             </div>
          </div>
      </div>
         

       
       
       
    </div>

    <Dialog open={showResults} onClose={()=>setShowResults(false)} maxWidth="lg"> 
        <div className="profile-myplans-section-view">
            <div className="profile-myplans-section-view-ctn">
                <div className="profile-myplans-section-view-head">
                    <h2>View</h2>
                </div>
                <div className="profile-myplans-section-view-body">
                    <div className="custom-login-input profile-single-input">
                        <p>Type of ERP</p>
                        <input type="text" disabled value={activePlanExpirySingle?.packageType} />
                    </div>
                    <div className="custom-login-input profile-single-input">
                        <p>Module</p>
                        <Select value={viewModules} sx={{width:"95%",background:'#ebf1ff;',borderColor:'white'}}
                        >
                          {
                             activePlanExpirySingle?.modules?.map((r)=>(
                              
                              <MenuItem value={0}>{r}</MenuItem>
                             ))
                                
                            
                          }
                        
                        </Select>
                    </div>
                    <div className="custom-login-input profile-single-input">
                        <p>Package Price</p>
                        <input type="text" disabled value={activePlanExpirySingle?.sellingPrice}/>
                    </div>
                    <div className="custom-login-input profile-single-input">
                        <p>Last Purchase Date</p>
                        <input type="date" value={activePlanExpirySingle?.purchaseDate}/>
                    </div>
                    
                    <div className="custom-login-input profile-single-input">
                        <p>Expiry Date</p>
                        <input  type="date" disabled value={activePlanExpirySingle?.expiryDate}/>
                    </div>
                </div>
                <div className="profile-myplans-section-view-footer">
                    {/* <button className='profile-myplans-section-view-renew-btn' >Renew</button> */}
                    <button className='profile-myplans-section-view-cancel-btn' onClick={viewClickCancel}>Close</button>
                </div>
            </div>
        </div>

       
    </Dialog>
     {/* cancel  dialog */}
     <Dialog open={cancelPopup} maxWidth="lg">
    <div className="profile-myplans-section-view">
            <div className="profile-myplans-section-view-ctn">
                <div className="profile-myplans-section-view-head">
                    <h2>Refund</h2>
                </div>
                <div className="profile-myplans-section-view-body">
                    <div className="custom-login-input profile-single-input">
                        <p>Customer Name</p>
                        <input type="text" disabled value={refundForm?.customerName} />
                    </div>
                    <div className="custom-login-input profile-single-input auto-complete">
                        <p>Amount</p>
                        <input type="text" disabled value={refundForm?.amount}/>
                        {/* <Autocomplete
                         options={["Textile ERP","Restaurant ERP"]}
                         getOptionLabel={(option)=>option}
                         renderInput={(params)=>(
                            <TextField {...params} />
                         )}
                                             
                        /> */}
                    </div>
                    <div className="custom-login-input profile-single-input">
                        <p>Reason</p>
                        <textarea 
                        type="text" 
                        value={refundForm?.reason}
                        onChange={(e)=>setReFundForm({...refundForm,reason:e.target.value})}
                        />
                    </div>
                    
                    
                   
                </div>
                <div className="profile-myplans-section-view-footer">
                    <button className='profile-myplans-section-view-cancel-btn' onClick={()=>setCancelPopup(false)}>Cancel</button>
                    <button onClick={refundButton} className='profile-myplans-section-view-renew-btn' >Refund</button>
                </div>
            </div>
        </div>
    </Dialog>

    {/* refund confirm Dialog */}
    <Dialog open={refundConfirm} maxWidth="lg">
       <div className='refund-confirm-dialog-container'>
         <div className='refund-confirm-dialog-sub-container'>
         <i class="bi bi-check-circle refund-check"></i>
         <p className='refund-confirm-dialog-first-p'>Refund has been initiated</p>   
         <p className='refund-confirm-dialog-second-p'>Refund will take up to 7 working days to reflect in your bank account.</p>
         <button className='profile-myplans-section-view-cancel-btn' onClick={()=>setRefundConfirm(false)}>Close</button>
         </div>
      
       </div>
    </Dialog>

   {/* RENEW DIALOG */}
   <Dialog open={renewDialog} maxWidth="lg">
     <div className="profile-myplans-section-view">
        <div className="profile-myplans-section-view-ctn">
            <div className="profile-myplans-section-view-head" style={{display:"flex",justifyContent:"center"}}>
                    <h3>Renewal</h3>
            </div>
            <div className='renew-button-container'>
               <div className='renew-erp-type'>
                  <img src="" alt="" />
                  <p>Textile ERP</p>
               </div>
               <div className='register-customer-container-second-radio'>
               <div 
                  className={`register-customer-container-second-radio-single ${
                    selectedOption === 'month' ? 'selected' : ''
                  }`}
                  onClick={() => handleOptionClick('month')}
                  >
                <p>Month</p>
               </div>
               <div 
                 className={`register-customer-container-second-radio-single ${
                    selectedOption === 'year' ? 'selected' : ''
                  }`}
                 onClick={() => handleOptionClick('year')}
                 >
               <p>Year</p>
               </div>
               </div>
            </div>
            <div style={{display:"flex",justifyContent:"flex-end",marginRight:"15%"}}>
              <p style={{fontSize:"0.7rem",fontWeight:600,color:"rgb(189 187 187)"}}>yearly 25% save</p>
            </div>
            <div className='register-customer-container-third'>
            <div className='register-customer-container-third-single'>
                <div className='register-customer-container-third-single-top'>
                     <p className='register-customer-card-std'>STANDARD</p>
                     <p className='register-customer-card-std-price'>AED 150/month</p>
                </div>
                <div className='register-customer-container-third-single-bottom'>
                    <div className='register-customer-card-icon-label'>
                        <div className='register-customer-card-icon'>
                           <i class="bi bi-check2" style={{color:"blue"}}></i>
                        </div>
                        <p>Among design professionals</p>
                    </div>
                    <div className='register-customer-card-icon-label'>
                        <div className='register-customer-card-icon'>
                           <i class="bi bi-check2" style={{color:"blue"}}></i>
                        </div>
                        <p>Among design professionals</p>
                    </div>
                    <div className='register-customer-card-icon-label'>
                        <div className='register-customer-card-icon'>
                           <i class="bi bi-check2" style={{color:"blue"}}></i>
                        </div>
                        <p>Among design professionals</p>
                    </div>
                    <div className='register-customer-card-icon-label'>
                        <div className='register-customer-card-icon'>
                           <i class="bi bi-check2" style={{color:"blue"}}></i>
                        </div>
                        <p>Among design professionals</p>
                    </div>
                    <button >PURCHASE</button>
                </div>
            </div>
            <div className='register-customer-container-third-single platinum-card'>
                <div className='register-customer-container-third-single-top platinum-card-top'>
                     <p className='register-customer-card-std'>PLATINUM</p>
                     <p className='register-customer-card-std-price'>AED 350/month</p>
                </div>
                <div className='register-customer-container-third-single-bottom platinum-card-bottom'>
                    <div className='register-customer-card-icon-label'>
                        <div className='register-customer-card-icon'>
                           <i class="bi bi-check2" style={{color:"blue"}}></i>
                        </div>
                        <p>Among design professionals</p>
                    </div>
                    <div className='register-customer-card-icon-label'>
                        <div className='register-customer-card-icon'>
                           <i class="bi bi-check2" style={{color:"blue"}}></i>
                        </div>
                        <p>Among design professionals</p>
                    </div>
                    <div className='register-customer-card-icon-label'>
                        <div className='register-customer-card-icon'>
                           <i class="bi bi-check2" style={{color:"blue"}}></i>
                        </div>
                        <p>Among design professionals</p>
                    </div>
                    <div className='register-customer-card-icon-label'>
                        <div className='register-customer-card-icon'>
                           <i class="bi bi-check2" style={{color:"blue"}}></i>
                        </div>
                        <p>Among design professionals</p>
                    </div>
                    <div className='register-customer-card-icon-label'>
                        <div className='register-customer-card-icon'>
                           <i class="bi bi-check2" style={{color:"blue"}}></i>
                        </div>
                        <p>Among design professionals</p>
                    </div>
                    <div className='register-customer-card-icon-label'>
                        <div className='register-customer-card-icon'>
                           <i class="bi bi-check2" style={{color:"blue"}}></i>
                        </div>
                        <p>Among design professionals</p>
                    </div>
                    <div className='register-customer-card-icon-label'>
                        <div className='register-customer-card-icon'>
                           <i class="bi bi-check2" style={{color:"blue"}}></i>
                        </div>
                        <p>Among design professionals</p>
                    </div>
                    <div className='register-customer-card-icon-label'>
                        <div className='register-customer-card-icon'>
                           <i class="bi bi-check2" style={{color:"blue"}}></i>
                        </div>
                        <p>Among design professionals</p>
                    </div>
                    <button >PURCHASE</button>
                </div>
            </div>
            </div>
            <div className="profile-myplans-section-view-head" style={{display:"flex",justifyContent:"center"}}>
                   <button onClick={()=>setRenewDialog(false)} style={{backgroundColor:"blue",width:"20%",height:"30px",border:"none",color:"#fff",cursor:'pointer'}}>Close</button>
            </div>
        </div>
     </div>
   </Dialog>
    <SuccessSnackBar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <ErrorSnackBar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
    </div>
  )
}
